import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Button = styled(Link)`
  color: var(--primaryColor);
  padding: 0px !important;
  margin: 2px 7px;
  height: fit-content;

  &:hover {
    opacity: 0.65;
  }
`;

export default function LinkButton({ to, text, target }) {
  return (
    <Button to={to} target={target}>
      {text}
    </Button>
  );
}

LinkButton.defaultProps = {
  target: "self",
  text: "Link",
  to: "/",
};
