import React, { useState } from "react";
import styled from "styled-components";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import axios from "axios";
import * as Yup from "yup";
import toast from "react-hot-toast";

import { BasicForm } from "@components/shared";
import { Button } from "@components/shared";
import { phoneValidation } from "@util/validation/phone";

const PhoneComponent = styled.div`
  .PhoneInput {
    input {
      border: 2px solid #ccc;
      padding: 0.25em 1em;
      border-radius: 6px;
    }
  }
`;

export default function PhoneForm({
  id,
  adminId,
  patientId,
  practiceId,
  userId,
  phoneValue,
  onSuccess,
}) {
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState("");

  const fields = [
    {
      name: "number",
      label: "Phone Number",
      initialValue: phoneValue,
      required: true,
      isPhone: true,
      schema: () =>
        Yup.string()
          .matches(phoneValidation, "Not a valid phone")
          .required("Required!"),
    },
  ];

  function onSubmit(formData) {
    setLoading(true);

    const req = {};

    if (adminId) {
      req.admin_id = adminId;
    }

    if (practiceId) {
      req.practice_id = practiceId;
    }

    if (userId) {
      req.user_id = userId;
    }

    if (patientId) {
      req.patient_id = patientId;
    }

    req.number = phone;

    if (id) return onUpdate(req);

    return onCreate(req);
  }

  function onCreate(formData) {
    const req = {
      ...formData,
    };

    axios
      .post(`/phones/`, req)
      .then(() => {
        setLoading(false);
        onSuccess();
        toast.success("Phone added!");
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  function onUpdate(formData) {
    const req = {
      ...formData,
      id,
    };

    axios
      .put(`/phones/${id}/`, req)
      .then(() => onSuccess())
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  // return <BasicForm fields={fields} loading={loading} onSubmit={onSubmit} />;

  return (
    <PhoneComponent style={{ display: "flex", padding: "0 15px 0 0" }}>
      <PhoneInput
        rules={{ required: true }}
        defaultCountry="GB"
        placeholder="Enter phone number"
        value={phone}
        onChange={setPhone}
      />
      <div style={{ marginLeft: "9px" }}>
        <Button.Basic
          onClick={onSubmit}
          primary
          type="submit"
          disabled={phone == null || phone.length == 0}
          loading={loading}
          text="Submit"
        />
      </div>
    </PhoneComponent>
  );
}

PhoneForm.defaultProps = {
  adminId: null,
  id: null,
  phoneValue: "",
  practiceId: null,
  userId: null,
};
