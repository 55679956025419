import React, { useState } from "react";
import * as Yup from "yup";
import toast from "react-hot-toast";
import axios from "axios";

import { BasicForm } from "@components/shared";

export default function DoctorForm({
  firstName,
  id,
  lastName,
  onSuccess,
  prefix,
  suffix,
}) {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  function onSubmit(postData) {
    setLoading(true);
    setError(null);

    if (id) {
      return onUpdate(postData);
    }

    onCreate(postData);
  }

  function onUpdate(formData) {
    axios
      .put(`/doctors/${id}/`, {
        ...formData,
        doctor_id: id,
      })
      .then(({ data }) => {
        setLoading(false);
      })
      .then(() => {
        toast.success("Doctor Updated!");
      })
      .then(() => {
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }

  function onCreate(formData) {
    axios
      .post(`/doctors/`, formData)
      .then(({ data }) => {
        setLoading(false);
        toast.success("Doctor Created!");
        onSuccess(data.response[0].id);
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }

  const fields = [
    {
      grouped: true,
      fields: [
        {
          name: "first_name",
          label: "First Name",
          initialValue: firstName,
          required: true,
          schema: () => Yup.string().required("Required"),
        },
        {
          name: "last_name",
          label: "Last Name",
          initialValue: lastName,
          required: true,
          schema: () => Yup.string().required("Required"),
        },
      ],
    },
    {
      grouped: true,
      fields: [
        {
          name: "prefix",
          initialValue: prefix,
          label: "Prefix",
          required: false,
          schema: () => Yup.string().min(2, "Too Short!"),
        },
        {
          name: "suffix",
          initialValue: suffix,
          label: "Suffix",
          required: false,
          schema: () => Yup.string().min(2, "Too Short!"),
        },
      ],
    },
  ];

  return (
    <BasicForm
      buttonText="Save"
      fields={fields}
      error={error}
      loading={isLoading}
      onSubmit={onSubmit}
    />
  );
}

DoctorForm.defaultProps = {
  firstName: "",
  id: null,
  lastName: "",
  prefix: "",
  suffix: "",
};
