import React from "react";
import { Toaster } from "react-hot-toast";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  AdminDetail,
  AdminIndex,
  Dashboard,
  DoctorIndex,
  DoctorDetail,
  OptionsIndex,
  PatientsDetail,
  PatientsIndex,
  PostOpDetail,
  PracticesDetail,
  PracticesIndex,
  PracticeUsersDetail,
  PracticeUsersIndex,
  ProcedureDetail,
  Profile,
  ReportsIndex,
  RegistryRequestDetail,
  RegistryRequestIndex,
} from "@pages/admin";
import { AppView, AppContainer } from "@components/Layouts";
import Nav from "@components/Nav";
import { NoMatch } from "@components/shared";

import ScrollToTop from "./ScrollToTop";

import { getRoleInfo } from "@util/roleInfo";

export default function Admin() {
  const roleInfo = getRoleInfo(useSelector((state) => state));

  return (
    <Router>
      <div style={{ height: "100%" }}>
        <Toaster
          toastOptions={{
            success: {
              iconTheme: {
                primary: "var(--positiveColor)",
              },
            },
          }}
        />
        <AppContainer>
          <Nav admin />
          <AppView>
            <ScrollToTop />
            <Switch>
              <Route exact path="/dashboard" component={Dashboard} />
              <Route exact path="/practices" component={PracticesIndex} />
              <Route path="/practices/:id" component={PracticesDetail} />
              <Route exact path="/patients" component={PatientsIndex} />
              <Route path="/patients/:id" component={PatientsDetail} />
              <Route path="/post-op-visits/:id" component={PostOpDetail} />
              <Route path="/procedures/:id" component={ProcedureDetail} />
              <Route exact path="/profile" component={Profile} />
              <Route path="/queries" component={ReportsIndex} />
              {!roleInfo.admin_research_fellow && [
                <Route exact path="/users" component={PracticeUsersIndex} />,
                <Route path="/users/:id" component={PracticeUsersDetail} />,
                <Route exact path="/admins" component={AdminIndex} />,
                <Route exact path="/admins/:id" component={AdminDetail} />,
                <Route exact path="/doctors" component={DoctorIndex} />,
                <Route path="/doctors/:id" component={DoctorDetail} />,
                <Route exact path="/options" component={OptionsIndex} />,
                <Route
                  exact
                  path="/registry-requests"
                  component={RegistryRequestIndex}
                />,
                <Route
                  path="/registry-requests/:id"
                  component={RegistryRequestDetail}
                />,
              ]}
              <Route path="*">
                <NoMatch />
              </Route>
            </Switch>
          </AppView>
        </AppContainer>
      </div>
    </Router>
  );
}
