import React from "react";
import styled from "styled-components";

export default function LabelActionButton({ buttonProps, children }) {
  return <Button {...buttonProps}>{children}</Button>;
}

export const Button = styled.button`
  font-family: inherit;
  border: none;
  padding: 0;
  outline: none;
  background: none;
  font-size: 20px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

  &:disabled {
    opacity: 0.25;
  }

  & + & {
    margin-left: 5px;
  }
`;
