import React, { useContext } from "react";
import styled from "styled-components";
import { FaUser, FaClipboardList } from "react-icons/fa";

import { Context } from "./";

const btns = [
  { text: "Patient", value: "patient", icon: <FaUser /> },
  { text: "Procedure", value: "procedure", icon: <FaClipboardList /> },
  {
    text: "Procedure Joins",
    value: "procedure_joins",
    icon: <FaClipboardList />,
  },
].map((m, i) => ({ ...m, key: i }));

export default function ViewPicker({ onClick }) {
  const { activeView } = useContext(Context);

  return (
    <StyledButtons>
      {btns.map((m) => (
        <StyledButton
          key={m.key}
          onClick={() => onClick(m.value)}
          active={activeView === m.value}
        >
          {m.icon} {m.text}
        </StyledButton>
      ))}
    </StyledButtons>
  );
}

const StyledButtons = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2em;
`;

const StyledButton = styled.button`
  width: 33%;
  background: #fff;
  font-size: 1.1em;
  font-family: inherit;
  padding: 12px 0;
  border-style: none;
  border-radius: 0px;
  border-bottom: ${(props) =>
    props.active ? "2px solid var(--primaryColor)" : "2px solid #b7b7b7"};
  cursor: pointer;
  color: ${(props) => (props.active ? "var(--primaryColor)" : "#b7b7b7")};
  font-weight: bold;
  text-align: center;
`;
