import React, { Component, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";

import { Button, Message } from "@components/shared";

export default class Login extends Component {
  state = {
    email: "",
    error: null,
    loading: false,
    password: "",
    remember: false,
    mfaInfo: null,
  };

  email = React.createRef();
  pw = React.createRef();

  componentDidMount = () => {
    const email = localStorage.getItem("email") || "";

    if (email) {
      this.setState({ email, remember: true });
      setTimeout(() => {
        this.pw.current.focus();
      }, 100);
    } else {
      this.setState({ email: "", remember: false });
      setTimeout(() => {
        this.email.current.focus();
      }, 100);
    }
  };

  handleChange = (e) =>
    this.setState({ [e.target.name]: e.target.value, error: null });

  onRememberCheck = (e) => {
    if (e.target.checked) {
      localStorage.setItem("email", this.state.email);
      this.setState({ remember: true });
    } else {
      localStorage.removeItem("email");
      this.setState({ remember: false });
    }
  };

  onSubmit = (e) => {
    e.preventDefault();

    const postData =
      this.props.entity === "User"
        ? {
            email: this.state.email,
            password: this.state.password,
          }
        : {
            email: this.state.email,
            admin: true,
            password: this.state.password,
          };

    this.setState({ loading: true });

    let postUrl = `/admin-login/`;

    if (this.props.entity === "User") {
      postUrl = `/user-login/`;
    }

    axios
      .post(postUrl, postData)
      .then(({ data }) => {
        if (data.response_code === 2 && data.response) {
          return this.setState({
            error: data.response_message,
            loading: false,
          });
        }

        if (data.response[0] && data.response[0].mfa) {
          return this.setState({
            mfaInfo: data.response[0],
          });
        }

        if (this.props.entity !== "User") {
          return (window.location = `dashboard`);
        }
        if (this.props.entity === "User") {
          return this.setUserPractice(data);
        }
      })
      .catch((err) => {
        this.setState({
          error: err,
          loading: false,
        });
      });
  };

  setUserPractice = (newUser) => {
    if (newUser.response[0].active_practices.length !== 1) {
      return (window.location = `dashboard`);
    }

    axios
      .get(
        `/practices/${newUser.response[0].active_practices[0].practice_id}/set-session/`
      )
      .then(() => {
        return (window.location = "patients");
      })
      .catch((err) => {
        return (window.location = `patients`);
      });
  };

  render() {
    const { entity } = this.props;
    const { error, mfaInfo } = this.state;

    if (localStorage.getItem("user")) {
      title = `Welcome back, ${JSON.parse(localStorage.getItem("user")).name}!`;
    }

    if (mfaInfo) {
      return (
        <React.Fragment>
          <MFA
            phoneId={mfaInfo.phone_id}
            adminId={mfaInfo.admin_id}
            userId={mfaInfo.user_id}
            onSuccess={(newUser) =>
              mfaInfo.admin_id
                ? (window.location = `/patients`)
                : this.setUserPractice(newUser)
            }
          />
        </React.Fragment>
      );
    }

    return (
      <div>
        <h1>{this.props.title}</h1>
        <form className="auth-form">
          <input
            ref={this.email}
            placeholder="Email"
            type="text"
            name="email"
            id="email"
            value={this.state.email}
            onChange={this.handleChange}
          />
          <input
            ref={this.pw}
            type="password"
            name="password"
            id="password"
            placeholder="Password"
            value={this.state.password}
            onChange={this.handleChange}
          />
          {error && (
            <Fade bottom>
              <Message negative text={error} />
            </Fade>
          )}
          <Button.Basic
            disabled={
              !this.state.email || !this.state.password || this.state.loading
                ? true
                : false
            }
            onClick={this.onSubmit}
            primary
            id="submit"
            type="submit"
            loading={this.state.loading}
            text="Login"
          />
        </form>
        <div className="auth-other">
          <Link
            style={{ marginBottom: "1em" }}
            to={
              entity === "User" ? `/forgot-password` : `/admin-forgot-password`
            }
          >
            Forgot Password?
          </Link>
          <div className="form-group">
            <input
              type="checkbox"
              onChange={this.onRememberCheck}
              checked={this.state.remember}
            />
            <label>Remember Me</label>
          </div>
        </div>
      </div>
    );
  }
}

Login.defaultProps = {
  title: "Login",
};

function MFA({ phoneId, adminId, userId, onSuccess }) {
  const [code, setCode] = useState("");
  const [error, setError] = useState(null);

  function onSubmit(e) {
    e.preventDefault();

    setError(null);

    const req = {
      code,
      phone_id: phoneId,
    };

    if (adminId) {
      req.admin_id = adminId;
    }

    if (userId) {
      req.user_id = userId;
    }

    axios
      .post(`/mfa-verification/`, req)
      .then(({ data }) => {
        onSuccess(data);
      })
      .catch((err) => {
        setError(err);
      });
  }

  return (
    <div>
      <h1>Enter the code sent to your email</h1>
      <form className="auth-form" onSubmit={onSubmit}>
        <input
          type="text"
          placeholder="Code"
          value={code}
          onChange={(e) => {
            setCode(e.target.value);
            setError(null);
          }}
        />
        {error && <p>{error}</p>}
        <Button.Basic
          disabled={!code || code.length !== 6}
          onClick={onSubmit}
          primary
          text="Submit"
        />
      </form>
    </div>
  );
}
