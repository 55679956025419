import React, { useState } from "react";
import { PatientForm, Table } from "@components/Patient";
import { Wrapped } from "@components/Layouts";
import { Button } from "@components/shared";

export default function PatientsIndex({}) {
  const [isOpen, setOpen] = useState(false);

  return (
    <div>
      <Wrapped
        title="Patients"
        // button={
        //   <Button.Toggle
        //     closedText="Add Patient"
        //     onClick={() => setOpen(!isOpen)}
        //     open={isOpen}
        //   />
        // }
      >
        {isOpen ? <PatientForm onSuccess={() => setOpen(false)} /> : <Table />}
      </Wrapped>
    </div>
  );
}
