import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form, Modal } from "semantic-ui-react";
import { FaPenSquare } from "react-icons/fa";

import { Button, Text } from "@components/shared";

export default function JoinNote({
  entityName,
  optionName,
  joinId,
  noteValue,
  onSuccess,
}) {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [value, setValue] = useState("");

  if (!entityName || !joinId) return null;

  useEffect(() => {
    setValue(noteValue);
  }, [noteValue]);

  function onClose() {
    setOpen(false);
    setLoading(false);
    setError(null);
  }

  function onSubmit() {
    setLoading(true);
    setError(null);

    const req = {
      entity: entityName,
      join_id: joinId,
      description: value,
    };

    axios
      .post(`/join-description/`, req)
      .then(() => {
        onClose();
        onSuccess();
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }

  return (
    <React.Fragment>
      <Button.Icon
        onClick={() => setOpen(true)}
        icon={<FaPenSquare />}
        title="Note"
        // text="Add/Edit Notes"
        opacity={1}
      />
      <Modal open={isOpen} onClose={onClose} size="small">
        <Modal.Header content={`${optionName} Notes`} />
        <Modal.Content>
          <Form>
            <Form.TextArea
              value={value}
              onChange={(e) => setValue(e.target.value)}
              placeholder="Your notes here"
            />
          </Form>
          {error && (
            <div style={{ marginTop: "1em" }}>
              <Text.Error text={error} />
            </div>
          )}
        </Modal.Content>
        <Modal.Actions>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button.Basic
              text="Close"
              onClick={onClose}
              style={{ marginRight: "5px" }}
            />
            <Button.Basic
              primary
              onClick={onSubmit}
              loading={loading}
              text="Save"
              disabled={loading}
            />
          </div>
        </Modal.Actions>
      </Modal>
    </React.Fragment>
  );
}

JoinNote.defaultProps = {
  noteValue: "",
  optionName: "",
};
