import React, { useState } from "react";
import { useSelector } from "react-redux";

import { Wrapped } from "@components/Layouts";
import { Button } from "@components/shared";
import { PostOpCreate, PostOpList } from "./";
import { getRoleInfo } from "@util/roleInfo";

export default function PostOpManagement({
  isAdmin,
  patientId,
  procedureId,
  procedureDate,
  onCreate,
  onUpdate,
}) {
  const [isOpen, setOpen] = useState(false);

  const roleInfo = getRoleInfo(useSelector((state) => state));

  return (
    <React.Fragment>
      <Wrapped
        title="Post-Op Visits"
        button={
          roleInfo.user_doctor || roleInfo.user_proxy ? (
            <Button.Toggle
              closedText="Add Post-Op"
              secondary
              onClick={() => setOpen(!isOpen)}
              open={isOpen}
            />
          ) : null
        }
      >
        {isOpen ? (
          <PostOpCreate
            patientId={patientId}
            procedureId={procedureId}
            procedureDate={procedureDate}
            onSuccess={(newVisitId) => {
              setOpen(false);
              onCreate(newVisitId);
            }}
          />
        ) : (
          <PostOpList
            procedureId={procedureId}
            reqData={{ procedure_id: procedureId }}
          />
        )}
      </Wrapped>
    </React.Fragment>
  );
}

PostOpManagement.defaultProps = {
  procedureDate: null,
};
