import React from "react";
import { OptionDescriptionList } from "@components/shared";
import formatEye from "@util/formatEye";

export function formatRetinalOption(opt) {
  const items = [
    {
      text: `${opt.nerve_variety_formatted} Variety`,
      value: opt.nerve_variety_formatted,
    },
  ]
    .filter((f) => ![null, undefined].includes(f.value))
    .map((m, i) => ({ ...m, key: i }));

  return <OptionDescriptionList items={items} />;
}

export function formatCataractOption(opt) {
  const items = [
    { text: `${opt.trace_formatted} Trace`, value: opt.trace_formatted },
  ]
    .filter((f) => ![null, undefined].includes(f.value))
    .map((m, i) => ({ ...m, key: i }));

  return <OptionDescriptionList items={items} />;
}

export function formatGonioscopyOption(opt) {
  const items = [
    {
      text: `${opt.iris_insertion_formatted} Insertion`,
      value: opt.iris_insertion_formatted,
    },
    {
      text: `${opt.meshwork_formatted} Pigmentation`,
      value: opt.meshwork_formatted,
    },
    {
      text: `${opt.peripheral_iris_formatted} Peripheral Iris`,
      value: opt.peripheral_iris_formatted,
    },
    {
      text: `${opt.angular_approach_formatted} Angle Approach`,
      value: opt.angular_approach_formatted,
    },
  ]
    .filter((f) => ![null, undefined].includes(f.value))
    .map((m, i) => ({ ...m, key: i }));
  return <OptionDescriptionList items={items} />;
}

export function formatMedName(opt) {
  return `${opt.name}${opt.value_formatted ? ` ${opt.value_formatted}` : ""}${
    ![null, undefined].includes(opt.eye) ? ` - ${formatEye(opt.eye)}` : ""
  }`;
}

export function formatNameWithEye(opt) {
  return `${opt.name}${opt.value_formatted ? `- ${opt.value_formatted}` : ""}${
    ![null, undefined].includes(opt.eye) ? ` - ${formatEye(opt.eye)}` : ""
  }`;
}
