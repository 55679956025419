import React, { useState } from "react";
import { Form } from "semantic-ui-react";

import { Button, FilterSelect, PaginatedTable } from "@components/shared";
import { statusOptions } from "./status";

const columns = [
  {
    Header: "",
    id: "view",
    width: 50,
    accessor: (d) => <Button.View url={`/practices/${d.id}`} />,
  },
  {
    Header: "Name",
    accessor: "name",
    id: "name",
    sortable: false,
  },
];

export default function Table() {
  const [activeFilter, setActiveFilter] = useState(1);
  const [searchValue, setSearchValue] = useState("");

  return (
    <React.Fragment>
      <FilterSelect
        options={dropdownOptions}
        value={activeFilter}
        onChange={(e) => setActiveFilter(e)}
        entityName="Practices"
      />
      <Form style={{ marginBottom: "1em" }}>
        <Form.Input
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          placeholder="Search practices..."
          width={4}
        />
      </Form>
      <PaginatedTable
        columns={columns}
        additionalPostData={{
          status: JSON.parse(activeFilter),
          value: searchValue.length >= 2 ? searchValue : null,
        }}
        entityName="Practices"
        filter={activeFilter}
        filterOptions={filterOptions}
        keyName="data"
        url="/retrieve-practices/"
      />
    </React.Fragment>
  );
}

const dropdownOptions = [
  { text: "All", value: JSON.stringify(statusOptions.map((s) => s.value)) },
  ...statusOptions,
];

const filterOptions = [
  { name: "name", obj: "self" },
  { name: "location_name", obj: "self" },
];
