import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { FaCheckCircle } from "react-icons/fa";
import Fade from "react-reveal/Fade";

import { Button, Message } from "@components/shared";

export default function ForgotPassword({ admin }) {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  function handleChange(e) {
    setEmail(e.target.value);
    setError(null);
  }

  function onSubmit(e) {
    setError(null);
    setLoading(true);

    const postData = {
      email,
      admin,
    };

    axios
      .post(`/forgot-password/`, postData)
      .then(() => {
        setSuccess(true);
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }

  if (success) {
    return (
      <div>
        <FaCheckCircle size={90} color="#05BF7D" />
        <h1>Email Sent!</h1>
        <p>Check your {email} inbox for password reset instructions.</p>
      </div>
    );
  }

  if (!success) {
    return (
      <div>
        <h1>Forgot Password?</h1>
        <p>Enter your email below to reset your password.</p>
        <form className="auth-form">
          <input
            placeholder="Email"
            type="text"
            name="email"
            value={email}
            onChange={(e) => handleChange(e)}
          />
          {error && (
            <Fade bottom>
              <Message text={error} negative />
            </Fade>
          )}
          <Button.Basic
            text="Send"
            primary
            type="submit"
            disabled={!email}
            onClick={(e) => {
              e.preventDefault();
              onSubmit();
            }}
          />
        </form>
        <Link
          style={{ marginTop: "1em", color: "initial", display: "block" }}
          to={admin ? `/admin` : `/`}
        >
          Remember password?
        </Link>
      </div>
    );
  }
}

ForgotPassword.defaultProps = {
  admin: false,
};
