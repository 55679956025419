import React, { useState, useEffect } from "react";
import axios from "axios";

import { PostOpForm } from "./";

const STEP_POSTOP = "postop";
const STEP_COMPLICATIONS = "complications";

export default function PostOpCreate({
  procedureDate,
  procedureId,
  onSuccess,
}) {
  const [activeStep, setActiveStep] = useState(STEP_POSTOP);
  const [postOpId, setPostOpId] = useState(null);
  const [postOp, setPostOp] = useState(null);
  const [existingVisits, setExistingVisits] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep]);

  useEffect(() => {
    axios
      .get(`/procedures/${procedureId}/`)
      .then(({ data }) => {
        setExistingVisits(
          data.response[0].post_op_visits_array.map((p) => p.variety)
        );
      })
      .catch((err) => {
        setExistingVisits([]);
      });
  }, []);

  return (
    <div>
      {activeStep === STEP_POSTOP && (
        <React.Fragment>
          <PostOpForm
            onSuccess={(newPostOp) => {
              onSuccess(newPostOp.id);
              // setActiveStep(STEP_COMPLICATIONS);
              // setPostOpId(newPostOp.id);
              // setPostOp(newPostOp);
            }}
            postOpData={postOp}
            procedureDate={procedureDate}
            id={postOpId}
            existingVisits={existingVisits}
            procedureId={procedureId}
          />
        </React.Fragment>
      )}
    </div>
  );
}

PostOpCreate.defaultProps = {
  procedureDate: null,
};
