import React, { useState } from "react";
import { useSelector } from "react-redux";

import { PracticeForm, Table } from "@components/Practice";
import { Wrapped } from "@components/Layouts";
import { Button } from "@components/shared";
import { getRoleInfo } from "@util/roleInfo";

export default function PracticesIndex({ history }) {
  const [isOpen, setOpen] = useState(false);

  const roleInfo = getRoleInfo(useSelector((state) => state));

  return (
    <div>
      <Wrapped
        title="Practices"
        button={
          (roleInfo.admin_grandmaster || roleInfo.admin_iridex) && (
            <Button.Toggle
              closedText="Add Practice"
              onClick={() => setOpen(!isOpen)}
              open={isOpen}
            />
          )
        }
      >
        {isOpen ? (
          <PracticeForm
            onSuccess={(createdId) => {
              history.push(`/practices/${createdId}`);
              setOpen(false);
            }}
          />
        ) : (
          <Table />
        )}
      </Wrapped>
    </div>
  );
}
