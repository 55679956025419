import React, { useState, useEffect } from "react";
import { Form, Modal } from "semantic-ui-react";
import axios from "axios";

import {
  BasicGrid,
  Button,
  DateField,
  JoinNote,
  JoinSection,
  OptionButton,
  OptionItem,
} from "@components/shared";

export default function PatientJoinEdit({ onUpdate, editData }) {
  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState(populateValues());

  const joinInfo = getJoinInfo(editData);

  useEffect(() => {
    setValues(populateValues(editData));
  }, [JSON.stringify(editData)]);

  function onClose() {
    setOpen(false);
    onUpdate();
    setLoading(false);
  }

  function onSaveClick() {
    if (!joinInfo) return alert("Something went terribly wrong...");

    setLoading(true);

    const req = {
      id: editData.join_id,
      entity: joinInfo.entity,
    };

    Object.keys(values)
      .filter((k) => values[k])
      .forEach((f) => (req[f] = values[f]));

    axios
      .post(`/update-join/`, req)
      .then(() => {
        onClose();
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  return (
    <Modal onClose={onClose} open>
      <Modal.Content>
        <Form>
          <Form.Field>
            <label>Eye</label>
            <Form.Group>
              <Form.Radio
                // label="Left"
                label="OS"
                onChange={() => setValues({ ...values, eye: 1 })}
                checked={values.eye === 1}
              />
              <Form.Radio
                // label="Right"
                label="OD"
                onChange={() => setValues({ ...values, eye: 2 })}
                checked={values.eye === 2}
              />
              <Form.Radio
                // label="Right"
                label="OU"
                onChange={() => setValues({ ...values, eye: 3 })}
                checked={values.eye === 3}
              />
            </Form.Group>
          </Form.Field>
          {/* {editData.operation_date && (
            <Form.Field>
              <label>Operation Date</label>
              <DateField
                selected={new Date(values.operation_date)}
                label="Operation Date"
                onChange={(e) =>
                  setValues({
                    ...values,
                    operation_date: e,
                  })
                }
              />
            </Form.Field>
          )} */}
          {editData &&
            editData.value_array &&
            editData.value_array.length > 0 && (
              <React.Fragment>
                <h4>Value?</h4>
                <BasicGrid>
                  {editData.value_array.map((m, i) => (
                    <OptionButton
                      key={i + 1}
                      text={m.text}
                      active={m.value === values.value}
                      onClick={() =>
                        setValues({
                          ...values,
                          value: m.value,
                        })
                      }
                    />
                  ))}
                </BasicGrid>
                {editData.dropdown_data.data.map((d) => (
                  <Form.Select
                    label={d.label}
                    position={d.position}
                    placeholder="Select one..."
                    options={d.options.map((opt) => ({
                      key: opt.value,
                      value: opt.value,
                      text: opt.text,
                    }))}
                    value={values[d.name]}
                    onChange={(e, opt) =>
                      setValues({
                        ...values,
                        [d.name]: opt.value,
                      })
                    }
                  />
                ))}
              </React.Fragment>
            )}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button.Basic text="Close" onClick={onClose} disabled={loading} />
          <Button.Basic
            text="Save"
            onClick={onSaveClick}
            loading={loading}
            disabled={loading}
            primary
            style={{ marginLeft: "8px" }}
          />
        </div>
      </Modal.Actions>
    </Modal>
  );
}

function populateValues(values = {}) {
  return {
    eye: values.eye || null,
    value: values.value || null,
  };
}

function getJoinInfo(opt) {
  if (opt.medication_id) return { entity: "MedProcedure" };
  if (opt.comorbidity_id) return { entity: "ProcedureComorbidity" };
  if (opt.glaucoma_diagnosis_id) return { entity: "DiagnosisProcedure" };
  if (opt.previous_ocular_surgery_id) return { entity: "PosProcedure" };

  return null;
}
