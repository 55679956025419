import React, { useState } from "react";
import { Form } from "semantic-ui-react";
import styled from "styled-components";
import axios from "axios";
import toast from "react-hot-toast";
import { getUKTime } from "@util/getUKTime";

import { Button, Icon, NumberField } from "@components/shared";

const varietyOptions = [
  { text: "First", value: 1 },
  { text: "Second", value: 2 },
  { text: "Third", value: 3 },
];

export default function ProcedureFormIops({
  creating,
  procedureId,
  items,
  onChange,
  fetchData,
  postopId,
  iop,
}) {
  function onItemChange(obj) {
    const newItems = [...items];
    const newItem = newItems.find((f) => f.variety === obj.variety);
    if (!newItem) return;
    newItem[obj.key] = obj.value;
    onChange(newItems);
  }

  if (creating) {
    const firstItem = items[0];
    return (
      <div>
        <h5>IOP Reading</h5>
        <Form.Select
          label="IOP Measurement"
          placeholder="Select one..."
          value={firstItem.variety}
          options={varietyOptions.map((m) => ({
            ...m,
          }))}
          onChange={(e, d) =>
            onItemChange({
              variety: firstItem.variety,
              key: "variety",
              value: d.value,
            })
          }
        />
        <Form.Group widths="equal">
          <Form.Input
            label="Time (xx:xx)"
            value={firstItem.time ? firstItem.time : getUKTime()}
            onChange={(e) =>
              onItemChange({
                variety: firstItem.variety,
                key: "time",
                value: e.target.value,
              })
            }
          />
          <Form.Field>
            <label>IOP (0-70 mmHg)</label>
            <NumberField
              value={firstItem.value}
              decimalScale={2}
              onValueChange={(e) =>
                onItemChange({
                  variety: firstItem.variety,
                  key: "value",
                  value: e.formattedValue,
                })
              }
              isAllowed={(values) => {
                const { floatValue } = values;
                return (
                  (floatValue >= 0 && floatValue <= 70) ||
                  floatValue === undefined
                );
              }}
            />
          </Form.Field>
        </Form.Group>
      </div>
    );
  }

  return (
    <div>
      <IopEntries
        procedureId={procedureId}
        postopId={postopId}
        entries={items}
        fetchData={fetchData}
      />
      {iop && <IopAverage>Combined IOP: {iop}</IopAverage>}
    </div>
  );
}

const IopAverage = styled.p`
  text-align: center;
  font-weight: bold;
  font-size: 1.35em;
`;

ProcedureFormIops.defaultProps = {
  items: [],
};

const IopEntries = ({ entries, postopId, procedureId, fetchData }) => {
  const [values, setValues] = useState({
    1: {
      time: "",
      value: "",
    },
    2: {
      time: "",
      value: "",
    },
    3: {
      time: "",
      value: "",
    },
  });

  const blocks = [
    { text: "First IOP", variety: 1 },
    { text: "Second IOP", variety: 2 },
    { text: "Third IOP", variety: 3 },
  ].map((m) => ({
    ...m,
    entry: entries.find((f) => f.variety === m.variety)
      ? {
          id: entries.find((f) => f.variety === m.variety).id,
          time: entries.find((f) => f.variety === m.variety).time,
          value: entries.find((f) => f.variety === m.variety).value,
        }
      : {
          id: null,
          time: getUKTime(),
          value: null,
        },
  }));

  const blocksWithEntryCount = blocks.filter(
    (f) => f.entry.value !== null
  ).length;

  function onAddSubmit(variety) {
    const req = {
      value: values[variety].value,
      variety,
      time: values[variety].time ? values[variety].time : getUKTime(),
    };

    if (procedureId) req.procedure_id = procedureId;
    if (postopId) req.post_op_visit_id = postopId;

    if (!procedureId && !postopId) return;

    axios
      .post(`/iop-entries/`, req)
      .then(() => {
        toast.success("Added!");
      })
      .then(() => {
        fetchData();
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  function onRemoveClick(id) {
    axios
      .delete(`/iop-entries/${id}/`)
      .then(() => {
        toast.success("Deleted!");
        fetchData();
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  return (
    <Grid>
      {blocks.map((b) => (
        <div key={b.variety}>
          {b.entry.value ? (
            <DisplayBlock>
              <div className="title">
                <h3 style={{ marginBottom: "5px" }}>{b.text}</h3>
                {blocksWithEntryCount > 1 && (
                  <Button.Icon
                    icon={
                      <Icon.Remove size={14} color="var(--negativeColor)" />
                    }
                    onClick={() => onRemoveClick(b.entry.id)}
                  />
                )}
              </div>
              <p>
                <Icon.Clock /> {b.entry.time}
              </p>
              <h1>
                {b.entry.value}
                <span>mmHg</span>
              </h1>
            </DisplayBlock>
          ) : (
            <React.Fragment>
              <h3 style={{ marginBottom: "5px" }}>{b.text}</h3>
              <Form>
                <Form.Input
                  label="Time (xx:xx)"
                  value={
                    values[b.variety].time
                      ? values[b.variety].time
                      : getUKTime()
                  }
                  onChange={(e) =>
                    setValues({
                      ...values,
                      [b.variety]: {
                        ...values[b.variety],
                        time: e.target.value,
                      },
                    })
                  }
                />
                <Form.Field>
                  <label>IOP (0-70 mmHg)</label>
                  <NumberField
                    value={values[b.variety].value}
                    decimalScale={2}
                    onValueChange={(e) =>
                      setValues({
                        ...values,
                        [b.variety]: {
                          ...values[b.variety],
                          value: e.formattedValue,
                        },
                      })
                    }
                    isAllowed={(values) => {
                      const { floatValue } = values;
                      return (
                        (floatValue >= 0 && floatValue <= 70) ||
                        floatValue === undefined
                      );
                    }}
                  />
                </Form.Field>
                <Button.Basic
                  style={{ marginBottom: "1em", width: "100%" }}
                  primary
                  text="Add Reading"
                  onClick={() => onAddSubmit(b.variety)}
                  disabled={!values[b.variety].value}
                />
              </Form>
            </React.Fragment>
          )}
        </div>
      ))}
    </Grid>
  );
};

const Grid = styled.div`
  display: grid;
  columns: 3;
  gap: 15px;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 2em;

  h3 {
    text-align: center;
  }
`;

const DisplayBlock = styled.div`
  text-align: center;

  h1 {
    font-size: 6rem;
    margin-bottom: 12px;
    display: inline-flex;
    margin-top: 0;

    span {
      font-size: 12px;
      margin-left: 5px;
    }
  }

  p {
    font-size: 1.2em;
    margin-top: 0;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-right: 5px;
      fill: #ccc;
    }
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: center;

    h3 {
      margin-right: 8px;
    }
  }
`;
