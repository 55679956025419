import React, { useState, useEffect } from "react";
import axios from "axios";

import {
  BasicGrid,
  Button,
  EmptyList,
  Card,
  Icon,
  JoinLabels,
} from "@components/shared";

import { bcvaFormatted } from "@components/Procedure/procedureOptions";
import { getUuid } from "@util/getUuid";

export default function PostOpList({ items, procedureId, showLink, reqData }) {
  const [loading, setLoading] = useState(false);
  const [visits, setVisits] = useState([]);

  useEffect(() => {
    setVisits(items);
  }, [JSON.stringify(items)]);

  useEffect(() => {
    fetchVisits();
  }, [JSON.stringify(reqData)]);

  function fetchVisits() {
    if (!reqData) return;

    const req = { ...reqData };

    setLoading(true);

    axios
      .post(`/retrieve-post-op-visits/`, req)
      .then(({ data }) => {
        setLoading(false);
        setVisits(data.response);
      })
      .catch((err) => {
        setVisits([]);
        setLoading(false);
      });
  }

  return (
    <React.Fragment>
      {loading && <p>Fetching visits...</p>}
      {!loading && visits.length === 0 && items.length === 0 && (
        <EmptyList text="No visits yet..." />
      )}
      <BasicGrid columns={1}>
        {visits
          .sort((a, b) => a.variety - b.variety)
          .map((v) => (
            <Card.Wrapper key={v.id}>
              <Card.Meta
                secondary
                hasActions={true}
                title={`${v.variety_formatted}${
                  v.post_op_date_formatted
                    ? ` (${v.post_op_date_formatted})`
                    : ""
                }`}
                stats={[
                  { title: "IOP", value: v.iop },
                  {
                    title: "BCVA",
                    value: bcvaFormatted(v.bcva, v.bcva_method),
                  },
                  { title: "Discomfort", value: v.patient_discomfort },
                ].filter((f) => f.value)}
              />
              {v.join_names && v.join_names.length > 0 && (
                <Card.Content secondary rounded={showLink ? false : true}>
                  <BasicGrid columns={2}>
                    {v.join_names
                      .filter((f) => f.joins.length)
                      .map((m, i) => (
                        <JoinLabels
                          key={getUuid()}
                          title={m.title}
                          secondary
                          joins={m.joins.map((mm) => ({
                            text: mm.text,
                            id: getUuid(),
                          }))}
                        />
                      ))}
                  </BasicGrid>
                </Card.Content>
              )}
              {showLink && (
                <Card.Actions secondary>
                  <Button.Link
                    to={`/post-op-visits/${v.id}`}
                    text={<Icon.Eye size={27} />}
                  />
                </Card.Actions>
              )}
            </Card.Wrapper>
          ))}
      </BasicGrid>
    </React.Fragment>
  );
}

PostOpList.defaultProps = {
  items: [],
  reqData: null,
  showLink: true,
};
