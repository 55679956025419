import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Switch, Route } from "react-router-dom";

import { Wrapped } from "@components/Layouts";
import { PageTitle, Message, ViewError } from "@components/shared";
import { ProcedureJoinManagement } from "@components/Procedure";
import { RegistryRequestApproval } from "@components/RegistryRequest";

import { PatientForm } from "@components/Patient";

export default function RegistryRequestDetail({ match }) {
  const { id } = match.params;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [regRequest, setRequest] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    setLoading(true);

    axios
      .get(`/registry-requests/${id}/`)
      .then(({ data }) => {
        setLoading(false);
        setRequest(data.response[0]);
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }

  if (error) {
    return <ViewError text={error} />;
  }

  if (!regRequest) return null;

  return (
    <div>
      <PageTitle
        title={"Registry Request"}
        links={[
          { text: "Patient", to: match.url },
          { text: "History", to: `${match.url}/history` },
        ].map((l, i) => ({ ...l, key: i + 1 }))}
        subtitle={
          <React.Fragment>
            from{" "}
            <Link to={`/practices/${regRequest.practice_id}`}>
              {regRequest.practice_name}
            </Link>
          </React.Fragment>
        }
      />
      <Wrapped>
        {regRequest.patient_ident && (
          <p>
            <b>Patient ID: {regRequest.patient_ident}</b>
          </p>
        )}
        {regRequest.approved_by ? (
          <Message
            text={`${regRequest.approved ? "Approved" : "Rejected"} by ${
              regRequest.approved_by
            }`}
            negative={!regRequest.approved}
          />
        ) : (
          <RegistryRequestApproval id={id} fetchData={fetchData} />
        )}
      </Wrapped>
      <Switch>
        <Route path={match.path} exact>
          <Wrapped>
            <PatientForm
              birthYear={regRequest.data.birth_year}
              newlyDiagnosedDate={regRequest.data.newly_diagnosed_date}
              dataConsentedAt={regRequest.data.data_consented_at}
              ethnicity={regRequest.data.ethnicity}
              gender={regRequest.data.gender}
              firstName={regRequest.data.first_name}
              lastName={regRequest.data.last_name}
              isRegistryRequest
            />
          </Wrapped>
        </Route>
        <Route path={`${match.path}/history`}>
          <Wrapped>
            <ProcedureJoinManagement rrId={id} viewOnly />
          </Wrapped>
        </Route>
      </Switch>
    </div>
  );
}
