import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

import { ProcedureForm, ProcedureJoinManagement } from "@components/Procedure";
import { procedureJoinKeys } from "@components/Procedure/ProcedureJoinManagement";
import { Button, Text } from "@components/shared";
import { populateProcedure } from "@pages/user/ProcedureDetail";
import { PatientPracticeSelect } from "@components/Patient";

import { eyeOptions } from "@util/eyeOptions";

const STEP_PATIENT = "patient";
const STEP_PATIENT_2 = "patient2";
const STEP_PATIENT_3 = "patient3";
const STEP_PROCEDURE = "procedure";
const STEP_EYE = "eye";

export default function ProcedureCreate({
  isAdmin,
  patientId,
  practiceId,
  onSuccess,
  eyeApproval,
}) {
  const [activeStep, setActiveStep] = useState(STEP_EYE);
  const [eye, setEye] = useState(null);
  const [procedureId, setProcedureId] = useState(null);
  const [procedureData, setProcedureData] = useState(null);
  const [selectedPracticeId, setSelectedPracticeId] = useState(null);

  const selectedEyeObj = eyeOptions.find((f) => f.value === eye);
  const userInfo = useSelector((state) => state).user;
  const preApprovalRequired =
    userInfo && userInfo.pre_approval_required ? true : false;

  useEffect(() => {
    setSelectedPracticeId(practiceId);
  }, [practiceId]);

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [activeStep]);

  function onEyeSelect() {
    const req = {
      practice_id: practiceId,
      patient_id: patientId,
      status: 1,
      eye: eye,
    };

    axios
      .post(`/procedures/`, req)
      .then(({ data }) => {
        setProcedureId(data.response[0].id);
        setActiveStep(STEP_PROCEDURE);
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  function onProcedureCreate(newProId) {
    if (!procedureId) {
      setProcedureId(newProId);
    }

    // setActiveStep(STEP_PATIENT);

    axios
      .get(`/procedures/${newProId || procedureId}/`)
      .then(({ data }) => {
        setProcedureData(populateProcedure(data.response[0]));
      })
      .catch((err) => {
        setProcedureData(null);
      });
  }

  function nextStep() {
    setActiveStep(STEP_PATIENT);

    axios
      .get(`/procedures/${procedureId}/`)
      .then(({ data }) => {
        setProcedureData(populateProcedure(data.response[0]));
      })
      .catch((err) => {
        setProcedureData(null);
      });
  }

  if (
    preApprovalRequired &&
    eye &&
    activeStep === STEP_PROCEDURE &&
    !eyeApproval[eye]
  ) {
    return <OtherEyeStager eye={eye} patientId={patientId} />;
  }

  return (
    <div>
      {activeStep === STEP_EYE && (
        <React.Fragment>
          {isAdmin && !procedureId && (
            <PatientPracticeSelect
              label="Which Practice Performed the Procedure?"
              patientId={patientId}
              value={selectedPracticeId}
              onChange={(e) => setSelectedPracticeId(e)}
            />
          )}
          <h2>Which eye?</h2>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "2em",
            }}
          >
            {eyeOptions
              .filter((f) => f.value !== 3)
              .map((m) => (
                <Button.Basic
                  text={m.text}
                  key={m.value}
                  style={{ marginRight: "8px" }}
                  primary={m.value === eye}
                  onClick={() => setEye(m.value)}
                />
              ))}
          </div>
          <Button.Basic
            primary
            disabled={!eye || (isAdmin && !selectedPracticeId)}
            text="Next Step"
            onClick={() => onEyeSelect()}
          />
        </React.Fragment>
      )}
      {activeStep === STEP_PROCEDURE && (
        <React.Fragment>
          <ProcedureForm
            id={procedureId}
            onUpdateSuccess={nextStep}
            onSuccess={onProcedureCreate}
            patientId={patientId}
            practiceId={selectedPracticeId}
            procedureData={procedureData}
            selectedEye={eye}
            showIOP={true}
          />
          <div style={{ marginTop: "1.5em" }}>
            <Button.Basic text="Back" onClick={() => setActiveStep(STEP_EYE)} />
          </div>
        </React.Fragment>
      )}
      {activeStep === STEP_PATIENT && (
        <React.Fragment>
          {selectedEyeObj && (
            <p style={{ marginBottom: "1em" }}>
              Showing <b>{selectedEyeObj.text}</b> related joins
            </p>
          )}
          <ProcedureJoinManagement
            selectedEye={eye}
            procedureId={procedureId}
            filters={[
              procedureJoinKeys.ocular_comorbidites,
              procedureJoinKeys.systemic_comorbidites,
              procedureJoinKeys.glaucoma_diagnoses,
              procedureJoinKeys.previous_ocular_surgery,
            ]}
          />
          <div
            style={{
              marginTop: "1.5em",
              display: "flex",
              alignItems: "center",
              marginBottom: "2em",
            }}
          >
            <Button.Basic
              text="Back"
              onClick={() => setActiveStep(STEP_PROCEDURE)}
              style={{ marginRight: "8px" }}
            />
            <Button.Basic
              primary
              text="Next Step"
              onClick={() => setActiveStep(STEP_PATIENT_2)}
            />
          </div>
        </React.Fragment>
      )}
      {activeStep === STEP_PATIENT_2 && (
        <React.Fragment>
          <ProcedureJoinManagement
            procedureId={procedureId}
            selectedEye={eye}
            filters={[
              procedureJoinKeys.gonioscopy,
              procedureJoinKeys.sle_finding,
              procedureJoinKeys.retinal_finding,
            ]}
          />
          <div
            style={{
              marginTop: "1.5em",
              display: "flex",
              alignItems: "center",
              marginBottom: "2em",
            }}
          >
            <Button.Basic
              text="Back"
              onClick={() => setActiveStep(STEP_PATIENT)}
              style={{ marginRight: "8px" }}
            />
            <Button.Basic
              primary
              text="Next Step"
              onClick={() => setActiveStep(STEP_PATIENT_3)}
            />
          </div>
        </React.Fragment>
      )}
      {activeStep === STEP_PATIENT_3 && (
        <React.Fragment>
          <ProcedureJoinManagement
            procedureId={procedureId}
            selectedEye={eye}
            filters={[
              procedureJoinKeys.ocular_medications,
              // procedureJoinKeys.systemic_medications,
              procedureJoinKeys.cataract_status,
            ]}
          />
          <div
            style={{
              marginTop: "1.5em",
              display: "flex",
              alignItems: "center",
              marginBottom: "2em",
            }}
          >
            <Button.Basic
              text="Back"
              onClick={() => setActiveStep(STEP_PATIENT_2)}
              style={{ marginRight: "8px" }}
            />
            <Button.Basic
              primary
              text="Finish"
              onClick={() => onSuccess(procedureId)}
            />
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

const OtherEyeStager = ({ patientId, eye }) => {
  const [registryRequestId, setRegistryRequestId] = useState(null);
  const [success, showSuccess] = useState(false);

  useEffect(() => {
    createRequest();
  }, []);

  function createRequest() {
    const req = {
      patient_id: patientId,
      projected_procedure_date: new Date(),
      eye,
    };

    axios
      .post(`/registry-requests/`, req)
      .then(({ data }) => {
        setRegistryRequestId(data.response[0].id);
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  if (!registryRequestId) return null;

  if (success) {
    return (
      <Text.Success text="Your request has been successfully submitted and is being processed by Iridex." />
    );
  }

  return (
    <div>
      <React.Fragment>
        <ProcedureJoinManagement rrId={registryRequestId} selectedEye={eye} />
        <div>
          <Button.Text text="I'm done here" onClick={() => showSuccess(true)} />
        </div>
      </React.Fragment>
    </div>
  );
};
