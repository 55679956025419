import React from "react";
import { Link } from "react-router-dom";
import { TiEye } from "react-icons/ti";

export default function TableViewButton({ url }) {
  return (
    <Link to={url}>
      <TiEye size={24} className="action-icon" />
    </Link>
  );
}
