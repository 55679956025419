import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form } from "semantic-ui-react";
import toast from "react-hot-toast";

import { Button } from "@components/shared";

export default function QueryContainerForm({
  sharedPractice,
  keep,
  name,
  description,
  id,
  queryData,
  onSuccess,
}) {
  const [descriptionValue, setDescription] = useState("");
  const [nameValue, setName] = useState("");
  const [keepValue, setKeep] = useState(false);
  const [sharedPracticeValue, setSharedPracticeValue] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setName(name);
  }, [name]);

  useEffect(() => {
    setDescription(description);
  }, [description]);

  useEffect(() => {
    setKeep(keep);
  }, [keep]);

  useEffect(() => {
    setSharedPracticeValue(sharedPractice);
  }, [sharedPractice]);

  function onSubmit() {
    setLoading(true);

    if (id) {
      return onUpdate();
    }

    const req = {
      name: nameValue,
      description: descriptionValue,
      query_data: JSON.stringify(queryData),
      keep: keepValue,
      shared_practice: sharedPracticeValue,
    };

    axios
      .post(`/query-containers/`, req)
      .then(({ data }) => {
        setLoading(false);
        window.location = `/queries/${data.response[0].id}`;
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  function onUpdate() {
    const req = {
      id,
      name: nameValue,
      description: descriptionValue,
      keep: keepValue,
      shared_practice: sharedPracticeValue,
    };

    axios
      .put(`/query-containers/${id}/`, req)
      .then(() => {
        toast.success("Query Updated!");
        setLoading(false);
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <Form>
      <Form.Input
        label="Name"
        value={nameValue}
        onChange={(e) => setName(e.target.value)}
      />
      <Form.TextArea
        label="Description (optional)"
        value={descriptionValue}
        onChange={(e) => setDescription(e.target.value)}
      />
      <Form.Radio
        toggle
        label="Keep for future use"
        checked={keepValue}
        onChange={() => {
          if (keepValue) {
            setSharedPracticeValue(false);
          }
          setKeep(!keepValue);
        }}
      />
      <Form.Radio
        toggle
        label="Make available to everyone in my practice"
        checked={sharedPracticeValue}
        onChange={() => setSharedPracticeValue(!sharedPracticeValue)}
        disabled={!keepValue}
      />
      <Button.Basic
        text={id ? "Save" : "Create"}
        onClick={onSubmit}
        loading={loading}
        disabled={!nameValue || loading}
        primary
      />
    </Form>
  );
}

QueryContainerForm.defaultProps = {
  description: "",
  keep: false,
  name: "",
};
