import NhsLookup from "./NhsLookup";
import PatientCreate from "./PatientCreate";
import PatientDisplay from "./PatientDisplay";
import PatientForm from "./PatientForm";
import PatientHistory from "./PatientHistory";
import PatientJoinEdit from "./PatientJoinEdit";
import PatientPractices from "./PatientPractices";
import PatientPracticeSelect from "./PatientPracticeSelect";
import PatientProcedures from "./PatientProcedures";
import PatientStager from "./PatientStager";
import Table from "./Table";

export {
  NhsLookup,
  PatientCreate,
  PatientDisplay,
  PatientForm,
  PatientHistory,
  PatientJoinEdit,
  PatientPractices,
  PatientProcedures,
  PatientPracticeSelect,
  PatientStager,
  Table,
};
