import React, { useState } from "react";
import axios from "axios";

import { PasswordForm } from "@components/Auth";

export default function ChangePassword({ adminId, userId, onSuccess }) {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  function onSubmit(formData) {
    setError(null);

    const req = {
      current_password: formData.password,
      password: formData.confirmedPassword,
      updated: true,
    };

    if (adminId) {
      req.admin_id = adminId;
      req.admin = true;
    }

    if (userId) {
      req.user_id = userId;
    }

    setLoading(true);

    axios
      .post(`/password/`, req)
      .then(() => {
        onSuccess();
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }

  return (
    <PasswordForm change onSubmit={onSubmit} error={error} loading={loading} />
  );
}

ChangePassword.defaultProps = {
  isAdmin: false,
};
