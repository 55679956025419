import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { useSelector } from "react-redux";

import { BasicForm } from "@components/shared";
import { genderOptions, ethnicityOptions } from "./patientOptions";

import { getRoleInfo } from "@util/roleInfo";

export default function PatientForm({
  birthYear,
  dataConsentedAt,
  ethnicity,
  firstName,
  gender,
  id,
  lastName,
  newlyDiagnosedDate,
  practiceId,
  status,
  staging,
  onSuccess,
  onFormSubmit,
  isRegistryRequest,
}) {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const roleInfo = getRoleInfo(useSelector((state) => state));

  function onSubmit(formData) {
    if (id) return onUpdate(formData);

    return onCreate(formData);
  }

  function onCreate(formData) {
    const req = {
      ...formData,
    };

    if (practiceId) {
      req.practice_id = practiceId;
    }

    if (staging) return onFormSubmit(req);

    setLoading(true);
    setError(null);

    axios
      .post(`/patients/`, req)
      .then(({ data }) => {
        setLoading(false);
        toast.success("Patient Created!");
        onSuccess(data.response[0].id);
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }

  function onUpdate(formData) {
    const req = {
      ...formData,
      patient_id: id,
    };

    setLoading(true);
    setError(null);

    axios
      .put(`/patients/${id}/`, req)
      .then(() => setLoading(false))
      .then(() => toast.success("Patient Updated!"))
      .then(() => onSuccess())
      .catch((err) => {
        setLoading(false);
        setError(err.response.data.ui_message);
      });
  }

  const fields = [
    // {
    //   name: "patient_ident",
    //   initialValue: "",
    //   label: "Patient ID",
    //   required: true,
    //   schema: () => Yup.string().required("Required"),
    // },
    {
      name: "first_name",
      initialValue: firstName,
      label: "First Name",
      required: true,
      schema: () => Yup.string().required("Required"),
    },
    {
      name: "last_name",
      initialValue: lastName,
      label: "Last Name",
      required: true,
      schema: () => Yup.string().required("Required"),
    },
    {
      name: "gender",
      initialValue: gender,
      label: "Gender",
      select: true,
      required: true,
      options: genderOptions,
      schema: () => Yup.number().nullable().required("Required"),
    },
    {
      name: "ethnicity",
      initialValue: ethnicity,
      label: "Ethnicity",
      select: true,
      required: true,
      options: ethnicityOptions,
      schema: () => Yup.number().nullable().required("Required"),
    },
    {
      grouped: true,
      fields: [
        {
          name: "birth_year",
          initialValue: birthYear,
          label: "Birth Year",
          required: true,
          schema: () =>
            Yup.string()
              .matches(/(?:19\d{2}|20[01][0-9]|2023)/, "Not a valid birth year")
              .required("Required"),
        },
        {
          name: "newly_diagnosed_date",
          initialValue: newlyDiagnosedDate
            ? new Date(newlyDiagnosedDate)
            : null,
          datepicker: true,
          maxDate: new Date(),
          label: "Newly Diagnosed Date",
          required: false,
          schema: () => Yup.string().nullable(),
        },
        {
          name: "data_consented_at",
          initialValue: dataConsentedAt ? new Date(dataConsentedAt) : null,
          datepicker: true,
          maxDate: new Date(),
          label: "Date Consented At",
          required: false,
          schema: () => Yup.string().nullable(),
        },
      ].map((m) => ({
        ...m,
        readOnly: isRegistryRequest
          ? true
          : id && !roleInfo.admin_research_fellow
          ? true
          : false,
      })),
    },
  ]
    .map((m) => ({
      ...m,
      readOnly: isRegistryRequest
        ? true
        : id && !roleInfo.admin_research_fellow
        ? true
        : false,
    }))
    .filter((f) => (!staging ? f.name !== "patient_ident" : true))
    .filter((f) =>
      isRegistryRequest
        ? true
        : roleInfo.admin_research_fellow || roleInfo.user_doctor
        ? true
        : !["first_name", "last_name"].includes(f.name)
    );

  return (
    <div>
      <BasicForm
        error={error}
        fields={fields}
        loading={isLoading}
        onSubmit={onSubmit}
        showButton={
          isRegistryRequest
            ? false
            : !id
            ? true
            : roleInfo.admin_research_fellow
            ? true
            : false
        }
      />
    </div>
  );
}

PatientForm.defaultProps = {
  birthdate: "",
  dataConsentedAt: "",
  ethnicity: null,
  firstName: "",
  gender: null,
  id: null,
  isRegistryRequest: false,
  lastName: "",
  newlyDiagnosedDate: "",
  practiceId: null,
  registryExitDate: "",
  staging: false,
  onSuccess: () => alert("On Success"),
};
