import React from "react";
import { useSelector } from "react-redux";

import { DoctorPractices } from "@components/Doctor";

export default function DoctorsIndex() {
  const currentPracticeId = useSelector((state) => state).user
    .session_practice_id;

  return <DoctorPractices practiceId={currentPracticeId} isPracticeUser />;
}
