import React from "react";
import { Switch, Route } from "react-router-dom";

import { Wrapped } from "@components/Layouts";
import QueryBuilder from "@components/QueryBuilder";
import { PageTitle } from "@components/shared";
import { QueryContainerList } from "@components/QueryContainer";
import { QueryContainerDetail } from "@pages/user";

export default function ReportsIndex({ match, isAdmin }) {
  const QueryNav = () => (
    <PageTitle
      title="Queries"
      links={[
        { text: "My Queries", to: match.url },
        { text: "Create Query", to: `${match.url}/create` },
      ]}
    />
  );
  return (
    <React.Fragment>
      <Switch>
        <Route path={match.path} exact>
          <React.Fragment>
            <QueryNav />
            <Wrapped>
              <QueryContainerList title="Queries" isAdmin={isAdmin} />
            </Wrapped>
          </React.Fragment>
        </Route>
        <Route path={`${match.path}/create`} exact>
          <React.Fragment>
            <QueryNav />
            <Wrapped>
              <QueryBuilder />
            </Wrapped>
          </React.Fragment>
        </Route>
        <Route
          path={`${match.path}/:id`}
          render={(routeProps) => (
            <React.Fragment>
              <PageTitle title="Report" />
              <QueryContainerDetail {...routeProps} isAdmin={isAdmin} />
            </React.Fragment>
          )}
        />
      </Switch>
    </React.Fragment>
  );
}
