export const SET_USER = 'SET_USER';

function userReducer(state, action) {
  switch (action.type) {
  case SET_USER:
    return {
      ...state,
      user: action.payload,
    };
  default:
    return state;
  }

  return state;
}

export default userReducer;
