import UserManagement from "./UserManagement";
import UserSetPractice from "./UserSetPractice";
import UserForm from "./UserForm";
import Table from "./Table";
import UserPracticeManagement from "./UserPracticeManagement";

export {
  UserForm,
  Table,
  UserSetPractice,
  UserPracticeManagement,
  UserManagement,
};
