import ProcedureCreate from "./ProcedureCreate";
import ProcedureForm from "./ProcedureForm";
import ProcedureFormIops from "./ProcedureFormIops";
import ProcedureJoinEdit from "./ProcedureJoinEdit";
import ProcedureJoinForm from "./ProcedureJoinForm";
import ProcedureJoins from "./ProcedureJoins";
import ProcedureJoinManagement from "./ProcedureJoinManagement";
import ProcedureList from "./ProcedureList";

export {
  ProcedureCreate,
  ProcedureJoinEdit,
  ProcedureJoinForm,
  ProcedureForm,
  ProcedureFormIops,
  ProcedureJoins,
  ProcedureJoinManagement,
  ProcedureList,
};
