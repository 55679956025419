import React, { useState, useEffect } from "react";
import axios from "axios";

import { BasicTable, Button, Icon } from "@components/shared";
import { bcvaFormatted } from "@components/Procedure/procedureOptions";

export default function QueryBuilderOutput({
  additionalRequestData,
  items,
  queryData,
  queryContainerId,
}) {
  const [reportData, setReportData] = useState(null);

  useEffect(() => {
    if (items.length) {
      setReportData({
        records: items,
      });
    }

    if (!items.length && queryData) {
      fetchData();
    }
  }, [JSON.stringify(items)]);

  function fetchData() {
    const req = {
      ...additionalRequestData,
      ...JSON.parse(queryData),
    };

    axios
      .post(`/procedure-report/`, req)
      .then(({ data }) => {
        setReportData({
          records: data.response,
        });
      })
      .catch((err) => {
        setReportData(null);
      });
  }

  if (!reportData) return null;

  const rows = reportData.records[0].data;

  return (
    <div>
      <p>
        <b>
          {rows.length} procedure
          {rows.length !== 1 ? "s" : ""}
        </b>{" "}
        in query
      </p>
      <p>Coming soon...</p>
      {/* <BasicTable
        columns={[
          {
            Header: "",
            width: 50,
            id: "view",
            accessor: (d) => (
              <Button.Link
                to={`/procedures/${d.id}`}
                text={<Icon.Eye />}
                target="_blank"
              />
            ),
          },
          {
            Header: "Operation Date",
            accessor: "operation_date",
            width: 200,
          },
          { Header: "IOP", accessor: "iop", width: 150 },
          {
            Header: "BCVA",
            id: "bcva",
            accessor: (d) => bcvaFormatted(d.bcva, d.bcva_method),
            width: 150,
          },
          { Header: "Practice", accessor: "practice_name" },
        ]}
        fetch={false}
        data={rows}
        entityName="Procedures"
      /> */}
    </div>
  );
}

QueryBuilderOutput.defaultProps = {
  items: [],
};
