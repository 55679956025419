import AdminDetail from "./AdminDetail";
import AdminIndex from "./AdminIndex";
import Dashboard from "./Dashboard";
import DoctorDetail from "./DoctorDetail";
import DoctorIndex from "./DoctorIndex";
import OptionsIndex from "./OptionsIndex";
import PatientsDetail from "./PatientsDetail";
import PatientsIndex from "./PatientsIndex";
import PostOpDetail from "./PostOpDetail";
import PracticesDetail from "./PracticesDetail";
import PracticesIndex from "./PracticesIndex";
import PracticeUsersDetail from "./PracticeUsersDetail";
import PracticeUsersIndex from "./PracticeUsersIndex";
import ProcedureDetail from "./ProcedureDetail";
import Profile from "./Profile";
import ReportsIndex from "./ReportsIndex";
import RegistryRequestDetail from "./RegistryRequestDetail";
import RegistryRequestIndex from "./RegistryRequestIndex";

export {
  AdminDetail,
  AdminIndex,
  Dashboard,
  DoctorDetail,
  DoctorIndex,
  OptionsIndex,
  PatientsDetail,
  PatientsIndex,
  PostOpDetail,
  PracticesDetail,
  PracticesIndex,
  PracticeUsersDetail,
  PracticeUsersIndex,
  ProcedureDetail,
  Profile,
  RegistryRequestDetail,
  RegistryRequestIndex,
  ReportsIndex,
};
