import React, { useState, useEffect } from "react";
import axios from "axios";
import { Switch, Route } from "react-router-dom";
import toast from "react-hot-toast";

import { Wrapped } from "@components/Layouts";
import { PageTitle, ViewError, Button } from "@components/shared";
import { UserForm, UserPracticeManagement } from "@components/PracticeUser";
import PhoneManagement from "@components/PhoneManagement";

export default function PracticeUserDetail({ match, isAdmin }) {
  const [user, setUser] = useState(populateUser());
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const id = match.params.id;

  useEffect(() => {
    fetchData();
  }, [id]);

  function fetchData() {
    axios
      .get(`/users/${id}/`)
      .then(({ data }) => {
        setUser(populateUser(data.response[0]));
      })
      .catch((err) => {
        setError(err);
      });
  }

  function resendSignupEmail() {
    setLoading(true);

    axios
      .post(`/users/resend-signup-email/`, { user_id: id })
      .then(() => {
        toast.success("Email Resent!");
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }

  if (error) {
    return <ViewError text={error} />;
  }

  return (
    <div>
      <PageTitle
        title={`${user.firstName} ${user.lastName}`}
        subtitle={"Practice User"}
        links={
          isAdmin
            ? [
                { text: "Detail", to: match.url },
                { text: "Practices", to: `${match.url}/practices` },
              ]
            : []
        }
      />
      <Switch>
        <Route path={match.path} exact>
          <React.Fragment>
            <Wrapped>
              <UserForm
                id={id}
                email={user.email}
                firstName={user.firstName}
                lastName={user.lastName}
                phone={user.phone}
                prefix={user.prefix}
                status={user.status}
                suffix={user.suffix}
                onSuccess={fetchData}
              />
              <Button.Basic
                style={{ marginTop: "20px" }}
                loading={loading}
                onClick={() => resendSignupEmail()}
                text="Resend Signup Email"
              />
            </Wrapped>
            <PhoneManagement userId={id} />
          </React.Fragment>
        </Route>
        {isAdmin && (
          <Route path={`${match.path}/practices`}>
            <Wrapped>
              <UserPracticeManagement
                userId={id}
                userPractices={user.active_practices}
                onUpdate={fetchData}
              />
            </Wrapped>
          </Route>
        )}
      </Switch>
    </div>
  );
}

function populateUser(d) {
  let data = { ...d };
  return {
    active_practices: data.active_practices || [],
    email: data.email || "",
    firstName: data.first_name || "",
    lastName: data.last_name || "",
    phone: data.phone || "",
    prefix: data.prefix || "",
    secondaryEmail: data.secondary_email || "",
    status: data.status,
    suffix: data.suffix || "",
  };
}
