import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { FaExclamationCircle } from "react-icons/fa";

import PasswordForm from "./PasswordForm";

class Code extends Component {
  state = {
    adminId: null,
    error: null,
    loading: true,
    submitting: false,
    userId: null,
    variety: null,
    verified: false,
  };

  componentDidMount = () => {
    this.verifyCode();
  };

  verifyCode = () => {
    const { id } = this.props.match.params;

    axios
      .get(`/verify-code/${id}/`)
      .then(({ data }) => {
        let code = data.response[0];

        this.setState({
          adminId: code.admin_id,
          codeId: code.id,
          userId: code.user_id,
          loading: false,
          variety: code.variety,
          verified: true,
        });
      })
      .catch((err) => {
        this.setState({
          error: err,
          loading: false,
          verified: false,
        });
      });
  };

  onPasswordSubmit = ({ password, confirmedPassword }) => {
    const { adminId, codeId, userId, variety } = this.state;

    const req = {
      password,
      confirmed_password: confirmedPassword,
      remote_code_id: codeId,
      update: variety === 1,
    };

    if (userId) {
      req.user_id = userId;
    }

    if (adminId) {
      req.admin_id = adminId;
      req.admin = true;
    }

    this.setState({
      submitting: true,
      error: null,
    });

    axios
      .post(`/password/`, req)
      .then(() => {
        if (adminId) {
          window.location = `/admin`;
        } else {
          window.location = `/`;
        }
      })
      .catch((err) => {
        this.setState({ submitting: false, error: err });

        setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      });
  };

  render() {
    const { error, loading, userId, adminId, variety, verified } = this.state;

    const text = varietyLib(variety);

    if (loading) {
      return <div></div>;
    }

    if (!verified && !loading) {
      return (
        <div>
          <FaExclamationCircle size={90} color="#D93030" />
          <h1>Oops!</h1>
          <p>{error}</p>
          <div className="auth-other">
            <Link to="/">Login</Link>
            <Link to="/forgot-password">Forgot Password</Link>
          </div>
        </div>
      );
    }

    return (
      <div>
        <h1>{text.header}</h1>
        <p>{text.body}</p>
        <PasswordForm
          disabled={this.state.submitting || this.state.error}
          error={this.state.error}
          onSubmit={this.onPasswordSubmit}
        />
      </div>
    );
  }
}

export default Code;

const varietyLib = (variety, entity = "Customer") => {
  switch (variety) {
    case 0:
      return {
        header: "Create Password",
        body: "Create the password for your account",
        path:
          entity === "Admin"
            ? "/admin-password-create/"
            : "/customer-password-create/",
      };
    case 1:
      return {
        header: "New Password",
        body: "Create a new password for your account",
        path:
          entity === "Admin"
            ? "/admin-password-reset/"
            : "/customer-password-reset/",
      };
    case 2:
      return {
        header: "New Password",
        body: "Create a new password for your account",
        path:
          entity === "Admin"
            ? "/admin-password-reset/"
            : "/customer-password-reset/",
      };
    default:
      break;
  }
};
