import React, { useContext } from "react";
import { Form } from "semantic-ui-react";
import styled from "styled-components";
import { FaCheckCircle, FaTrashAlt } from "react-icons/fa";

import MinMaxFields from "./MinMaxFields";
import { Context } from "./";
import ValueBlock from "./ValueBlock";
import { BasicGrid } from "@components/shared";

import LabelActionButton from "./LabelActionButton";

function generateRandomInteger(min, max) {
  return Math.floor(min + Math.random() * (max + 1 - min));
}

import {
  ethnicityOptions,
  genderOptions,
} from "@components/Patient/patientOptions";

import {
  bcvaOptions,
  lensOptions,
  powerOptions,
  sweepCountOptions,
  sweepVelocityOptions,
  techniqueOptions,
} from "@components/Procedure/procedureOptions";

const patientFields = [
  // { label: "Min Age", name: "age_min", min: 0, max: 100 },
  // { label: "Max Age", name: "age_max", min: 0, max: 100 },
  { label: "Gender", name: "gender", select: true, options: genderOptions },
  {
    label: "Ethnicity",
    name: "ethnicity",
    select: true,
    options: ethnicityOptions,
  },
].map((m, i) => ({
  ...m,
  key: i + 1,
}));

const procedureFields = [
  // { label: "IOP Min", name: "iop_min", min: 0, max: 70 },
  // { label: "IOP Max", name: "iop_max", min: 0, max: 70 },
  // { label: "Axial Length Min", name: "axial_length_min", min: 0, max: 30 },
  // { label: "Axial Length Max", name: "axial_length_max", min: 0, max: 30 },
  {
    label: "Lens Status",
    name: "lens_status",
    select: true,
    options: lensOptions,
  },
  {
    label: "BCVA",
    name: "bcva",
    select: true,
    options: bcvaOptions(),
  },
  {
    label: "Power",
    name: "power",
    select: true,
    options: powerOptions,
  },
  {
    label: "Technique",
    name: "technique",
    select: true,
    options: techniqueOptions,
  },
  {
    label: "Sweep Speed Velocity",
    name: "sweep_velocity",
    select: true,
    options: sweepVelocityOptions,
  },
  {
    label: "Number of Sweeps",
    name: "sweep_count",
    select: true,
    options: sweepCountOptions,
  },
].map((m, i) => ({
  ...m,
  key: i + 1,
}));

export default function QueryForm({
  onChange,
  onPatientChange,
  onProcedureChange,
}) {
  const { activeView, loading, patientValues, procedureValues } =
    useContext(Context);

  function onRandomClick() {
    const randomNum = Math.random();

    const procedureInfo = procedureFields.reduce((acc, cur) => {
      if (cur.select) {
        acc[cur.name] = [...cur.options.map((m) => m.value)].slice(
          generateRandomInteger(0, cur.options.length),
          cur.options.length
        );
      } else {
        acc[cur.name] =
          randomNum > 0.45 ? generateRandomInteger(cur.min, cur.max) : "";
      }

      return acc;
    }, {});

    const patientInfo = patientFields.reduce((acc, cur) => {
      if (cur.select) {
        acc[cur.name] = [...cur.options.map((m) => m.value)].slice(
          generateRandomInteger(0, cur.options.length),
          cur.options.length
        );
      } else {
        acc[cur.name] =
          randomNum > 0.45 ? generateRandomInteger(cur.min, cur.max) : "";
      }

      return acc;
    }, {});

    onChange({
      patientValues: { ...patientInfo },
      procedureValues: {
        ...procedureInfo,
      },
    });
  }

  return (
    <Form>
      {/* <button type="button" onClick={onRandomClick}>
        Generate random
      </button> */}
      {activeView === "patient" && (
        <React.Fragment>
          <MinMaxFields
            fieldTitle="Age"
            minValue={patientValues.age_min}
            maxValue={patientValues.age_max}
            onMinChange={(e) => onPatientChange({ age_min: e })}
            onMaxChange={(e) => onPatientChange({ age_max: e })}
            onClearClick={() => onPatientChange({ age_min: "", age_max: "" })}
          />
          {patientFields.map((f) => {
            if (f.select) {
              return (
                <CustomSelect
                  key={f.key}
                  disabled={loading}
                  label={f.label}
                  value={patientValues[f.name]}
                  onChange={(e, d) => onPatientChange({ [f.name]: d.value })}
                  options={f.options}
                />
              );
            }
            return (
              <Form.Input
                key={f.key}
                disabled={loading}
                label={f.label}
                value={patientValues[f.name]}
                onChange={(e) => onPatientChange({ [f.name]: e.target.value })}
              />
            );
          })}
        </React.Fragment>
      )}
      {activeView === "procedure" && (
        <React.Fragment>
          <InputGroup>
            <MinMaxFields
              fieldTitle="IOP"
              minValue={procedureValues.iop_min}
              maxValue={procedureValues.iop_max}
              onMinChange={(e) => onProcedureChange({ iop_min: e })}
              onMaxChange={(e) => onProcedureChange({ iop_max: e })}
              onClearClick={() =>
                onProcedureChange({ iop_min: "", iop_max: "" })
              }
            />
            <MinMaxFields
              fieldTitle="Axial Length"
              minValue={procedureValues.axial_length_min}
              maxValue={procedureValues.axial_length_max}
              onMinChange={(e) => onProcedureChange({ axial_length_min: e })}
              onMaxChange={(e) => onProcedureChange({ axial_length_max: e })}
              onClearClick={() =>
                onProcedureChange({
                  axial_length_min: "",
                  axial_length_max: "",
                })
              }
            />
          </InputGroup>
          {procedureFields.map((f) => {
            if (f.select) {
              return (
                <CustomSelect
                  key={f.key}
                  disabled={loading}
                  label={f.label}
                  value={procedureValues[f.name]}
                  onChange={(e, d) => onProcedureChange({ [f.name]: d.value })}
                  options={f.options}
                />
              );
            }
            return (
              <Form.Input
                key={f.key}
                disabled={loading}
                label={f.label}
                value={procedureValues[f.name]}
                onChange={(e) =>
                  onProcedureChange({ [f.name]: e.target.value })
                }
              />
            );
          })}
        </React.Fragment>
      )}
    </Form>
  );
}

const CustomSelect = (props) => {
  return (
    <StyledSelect>
      <div className="meta">
        <p>
          <b>{props.label}</b>
        </p>
        <div className="actions">
          <LabelActionButton
            buttonProps={{
              type: "button",
              title: "Select all",
              disabled: props.value.length === props.options.length,
              onClick: () =>
                props.onChange(null, {
                  value: props.options.map((m) => m.value),
                }),
            }}
          >
            <FaCheckCircle color="var(--primaryColorLight)" />
          </LabelActionButton>
          <LabelActionButton
            buttonProps={{
              type: "button",
              disabled: props.value.length === 0,
              title: "Remove all",
              onClick: () =>
                props.onChange(null, {
                  value: [],
                }),
            }}
          >
            <FaTrashAlt color="#d9d9d9" />
          </LabelActionButton>
        </div>
      </div>
      <StyledGrid>
        <BasicGrid columns={4}>
          {props.options.map((m) => (
            <ValueBlock
              name={m.text}
              active={props.value.includes(m.value)}
              key={m.value}
              onAddClick={() => {
                props.onChange(null, {
                  value: props.value.includes(m.value)
                    ? [...props.value].filter((f) => f !== opt.value)
                    : [...props.value, m.value],
                });
              }}
              onRemoveClick={() =>
                props.onChange(null, {
                  value: [...props.value].filter((f) => f !== m.value),
                })
              }
            />
          ))}
        </BasicGrid>
      </StyledGrid>
    </StyledSelect>
  );
};

const StyledSelect = styled.div`
  & + & {
    margin-top: 2em;
  }

  &:last-of-type {
    margin-bottom: 1.25em;
  }

  .meta {
    display: flex;
    align-items: center;
    margin-bottom: 0.65em;

    p {
      margin-bottom: 0;
      margin-right: 8px;
      font-size: 1.25em;
    }
  }

  .meta .actions {
    display: flex;
    align-items: center;
    position: relative;
    top: 2px;
  }
`;

const StyledGrid = styled.div`
  max-height: 300px;
  overflow-y: auto;
`;

const InputGroup = styled.div`
  display: flex;
  align-items: center;

  div:first-of-type {
    width: 60%;
  }
`;
