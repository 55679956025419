export const medFrequencyOptions = [
  { value: 1, text: "QD" },
  { value: 2, text: "BID" },
  { value: 3, text: "TID" },
  { value: 4, text: "QID" },
  { value: 5, text: "Q3 Hours" },
  { value: 6, text: "Q2 Hours" },
  { value: 7, text: "Q1 Hours" },
  { value: 8, text: "PO" },
  { value: 9, text: "QHS" },
];

export const statusOptions = [
  { value: 1, text: "active" },
  { value: 2, text: "inactive" },
  { value: 3, text: "deleted" },
];

export const bcvaMethodOptions = [
  { value: 1, text: "Snellen" },
  { value: 2, text: "LogMAR" },
];

export const lensOptions = [
  { value: 1, text: "Phakic" },
  { value: 2, text: "Psuedophakic" },
  { value: 3, text: "Aphakic" },
];

export const bcvaOptions = (method) =>
  [
    { value: 1, logmar: "1.6", snellen: "20/800", letter: "5" },
    { value: 2, logmar: "1.5", snellen: "20/640", letter: "10" },
    { value: 3, logmar: "1.4", snellen: "20/500", letter: "15" },
    { value: 4, logmar: "1.3", snellen: "20/400", letter: "20" },
    { value: 5, logmar: "1.2", snellen: "20/320", letter: "25" },
    { value: 6, logmar: "1.1", snellen: "20/250", letter: "30" },
    { value: 7, logmar: "1.0", snellen: "20/200", letter: "35" },
    { value: 8, logmar: "0.9", snellen: "20/160", letter: "40" },
    { value: 9, logmar: "0.8", snellen: "20/125", letter: "45" },
    { value: 10, logmar: "0.7", snellen: "20/100", letter: "50" },
    { value: 11, logmar: "0.6", snellen: "20/80", letter: "55" },
    { value: 12, logmar: "0.5", snellen: "20/63", letter: "60" },
    { value: 13, logmar: "0.4", snellen: "20/50", letter: "65" },
    { value: 14, logmar: "0.3", snellen: "20/40", letter: "70" },
    { value: 15, logmar: "0.2", snellen: "20/32", letter: "75" },
    { value: 16, logmar: "0.1", snellen: "20/25", letter: "80" },
    { value: 17, logmar: "0.0", snellen: "20/20", letter: "85" },
    { value: 18, logmar: "-0.1", snellen: "20/15", letter: "90" },
    { value: 19, logmar: "-0.2", snellen: "20/12", letter: "95" },
  ].map((m) => ({
    key: m.value,
    value: m.value,
    text: !method ? m.letter : method === 1 ? m.snellen : m.logmar,
  }));

export function bcvaFormatted(bcvaValue, methodValue) {
  const obj = bcvaOptions(methodValue).find((f) => f.value === bcvaValue);
  return obj ? obj.text : bcvaValue;
}

export const powerOptions = [
  { text: "2000mW", value: 2000 },
  { text: "2250mW", value: 2250 },
  { text: "2500mW", value: 2500 },
  { text: "2750mW", value: 2750 },
  { text: "3000mW", value: 3000 },
];

export const techniqueOptions = [
  { text: "Hemisphere", value: 1, sections: 2 },
  { text: "Quadrant", value: 2, sections: 4 },
  { text: "Discrete Spot", value: 3, sections: null },
];

export const sweepVelocityOptions = [
  { text: "10 Second", value: 1 },
  { text: "15 Seconds", value: 2 },
  { text: "20 Seconds", value: 3 },
  { text: "25 Seconds", value: 4 },
  { text: "30 Seconds", value: 5 },
  { text: "35 Seconds", value: 6 },
  { text: "40 Seconds", value: 7 },
  { text: "Discrete Spot", value: 8 },
];

export const sweepCountOptions = [
  { text: "1", value: 1 },
  { text: "2", value: 2 },
  { text: "3", value: 3 },
  { text: "4", value: 4 },
  { text: "5", value: 5 },
  { text: "6", value: 6 },
  { text: "7", value: 7 },
  { text: "8", value: 8 },
  { text: "9", value: 9 },
  { text: "10", value: 10 },
  { text: "Discrete Spot", value: 11 },
];

export const iopVarietyOptions = [
  { text: "Goldmann", value: 1 },
  { text: "Tonopen", value: 2 },
  { text: "Rebound Tonometry", value: 3 },
  { text: "Pseudo-Tonometry", value: 4 },
];

function generateOptions(min, max, step) {
  let arr = [];

  for (let i = min; i <= max; i += step) {
    arr.push(i);
  }

  return arr;
}

export const wavePowerOptions = generateOptions(1000, 3000, 50).map((m) => ({
  key: m,
  value: m,
  text: `${m}mW`,
}));
export const waveDurationOptions = generateOptions(1000, 20000, 50).map(
  (m) => ({
    key: m,
    value: m,
    text: `${m}ms`,
  })
);
export const waveSpotOptions = generateOptions(1, 30, 1).map((m) => ({
  key: m,
  value: m,
  text: `${m}`,
}));
