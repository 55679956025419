import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal } from "semantic-ui-react";
import { connect } from "react-redux";
import toast from "react-hot-toast";
import { Wrapped } from "@components/Layouts";
import { UserForm } from "@components/PracticeUser";
import { ChangePassword } from "@components/Profile";
import PhoneManagement from "@components/PhoneManagement";
import { Button } from "@components/shared";

function Profile({ userId }) {
  const [profile, setProfile] = useState(populateProfile());

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    axios
      .get(`/users/${userId}/`)
      .then(({ data }) => {
        setProfile(populateProfile(data.response[0]));
      })
      .catch((err) => {});
  }

  if (!userId) return null;

  return (
    <React.Fragment>
      <Wrapped title="Profile">
        <UserForm
          email={profile.email}
          firstName={profile.firstName}
          id={userId}
          isProfile
          lastName={profile.lastName}
          mfa={profile.mfa}
          onSuccess={fetchData}
          prefix={profile.prefix}
          suffix={profile.suffix}
        />
      </Wrapped>
      <PhoneManagement userId={userId} />
      <Wrapped>
        <ChangePw id={profile.id} />
      </Wrapped>
    </React.Fragment>
  );
}

function mapStateToProps(state) {
  return {
    userId: state.user ? state.user.id : null,
  };
}

export default connect(mapStateToProps)(Profile);

function populateProfile(d) {
  let data = { ...d };
  return {
    allow_master: data.allow_master,
    email: data.email || "",
    firstName: data.first_name || "",
    id: data.id,
    lastName: data.last_name || "",
    mfa: data.mfa || false,
    prefix: data.prefix || "",
    suffix: data.suffix || "",
  };
}

const ChangePw = ({ id }) => {
  const [isOpen, setOpen] = useState(false);

  function onClose() {
    setOpen(false);
  }

  return (
    <React.Fragment>
      <Button.Basic text="Change Password" onClick={() => setOpen(true)} />
      <Modal open={isOpen} onClose={onClose} size="tiny">
        <Modal.Content>
          <ChangePassword
            userId={id}
            onSuccess={() => {
              toast.success("Password updated!");
              onClose();
            }}
          />
        </Modal.Content>
        <Modal.Actions>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button.Basic text="Close" onClick={onClose} />
          </div>
        </Modal.Actions>
      </Modal>
    </React.Fragment>
  );
};
