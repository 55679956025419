import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import {
  AuthWrapper,
  Code,
  ForgotPassword,
  Login,
  NotFound,
} from "@components/Auth";

class Auth extends Component {
  render() {
    if (this.props.error) {
      return (
        <AuthWrapper>
          <h1>Status Error!</h1>
          <p>There was a problem. Try again later.</p>
        </AuthWrapper>
      );
    }

    return (
      <Router>
        <Switch>
          <Route
            exact
            path="/"
            render={(props) => (
              <AuthWrapper>
                <Login entity="User" title="Login" {...props} />
              </AuthWrapper>
            )}
          />
          <Route
            exact
            path="/admin"
            render={(props) => (
              <AuthWrapper>
                <Login entity="Admin" title="Admin Login" {...props} />
              </AuthWrapper>
            )}
          />
          <Route
            path="/code/:id"
            render={(props) => (
              <AuthWrapper>
                <Code {...props} />
              </AuthWrapper>
            )}
          />
          <Route
            path="/forgot-password"
            render={(props) => (
              <AuthWrapper>
                <ForgotPassword {...props} />
              </AuthWrapper>
            )}
          />
          <Route
            path="/admin-forgot-password"
            render={(props) => (
              <AuthWrapper>
                <ForgotPassword {...props} admin />
              </AuthWrapper>
            )}
          />
          <Route path="*">
            <AuthWrapper>
              <NotFound />
            </AuthWrapper>
          </Route>
        </Switch>
      </Router>
    );
  }
}

export default Auth;

Auth.defaultProps = {
  error: false,
};
