import React from "react";

import { Wrapped } from "@components/Layouts";
import { RegistryRequestTable } from "@components/RegistryRequest";

export default function RegistryRequestIndex() {
  return (
    <div>
      <Wrapped title="Registry Requests">
        <RegistryRequestTable />
      </Wrapped>
    </div>
  );
}
