import React from "react";
import styled from "styled-components";
import { Button, Icon } from "@components/shared";

const borderRadius = "8px";
const backgroundColor = "#eee";
const border = "1px solid #dfdfdf";

const ValueBlock = ({
  active,
  name,
  dropdownInfo,
  onRemoveClick,
  loading,
  onAddClick,
}) => {
  return (
    <StyledValueBlock
      active={active}
      onClick={() => {
        if (active) return;

        if (onAddClick) {
          return onAddClick();
        }
      }}
    >
      <p className="name">
        <b>{name}</b>{" "}
        {active && (
          <Button.Icon
            onClick={onRemoveClick}
            icon={<Icon.Remove size={14} />}
            title="Remove"
            disabled={loading}
            opacity={loading ? "0.5" : "1"}
          />
        )}
      </p>
      {dropdownInfo.length > 0 && (
        <div className="dropdown-info">
          {dropdownInfo.map((m, i) => (
            <div key={m.key}>
              <p>
                <b>{m.value[0].key_name_formatted}:</b>
                <span style={{ marginLeft: "5px" }}>
                  {new Intl.ListFormat("en", {
                    style: "narrow",
                  }).format(m.value.map((f) => `${f.text}`))}
                </span>
              </p>
            </div>
          ))}
        </div>
      )}
    </StyledValueBlock>
  );
};

ValueBlock.defaultProps = {
  active: false,
  name: "",
  dropdownInfo: [],
};

export default ValueBlock;

const StyledValueBlock = styled.div`
  background: ${(props) =>
    props.active ? "var(--primaryColor)" : backgroundColor};
  padding: 18px;
  border-radius: ${borderRadius};
  border: ${border};
  cursor: ${(props) => (!props.active ? "pointer" : "initial")};
  color: ${(props) => (props.active ? "#fff" : "initial")};

  .name {
    display: flex;
    align-items: center;
    font-size: 1.15em;
    margin: 0;

    button {
      margin-left: 8px;
      color: ${(props) => (props.active ? "#fff" : "var(--negativeColor)")};
    }
  }

  .dropdown-info {
    margin-top: 0.25em;
  }
`;
