import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";

import { Button, Text } from "@components/shared";
import { ProcedureJoinManagement } from "@components/Procedure";
import { PatientForm } from "@components/Patient";

import { eyeOptions } from "@util/eyeOptions";

export default function PatientStager() {
  const [registryRequestId, setRegistryRequestId] = useState(null);
  const [eye, setEye] = useState(null);
  const [success, showSuccess] = useState(false);

  function onPatientSubmit(patientData) {
    const req = {
      data: patientData,
      projected_procedure_date: new Date(),
      eye,
    };
    axios
      .post(`/registry-requests/`, req)
      .then(({ data }) => {
        setRegistryRequestId(data.response[0].id);
      })
      .catch((err) => {
        toast.error(err);
      });
  }

  if (success) {
    return (
      <Text.Success text="Your request has been successfully submitted and is being processed by Iridex." />
    );
  }

  if (!eye) {
    return (
      <React.Fragment>
        <h2>Which eye?</h2>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "2em",
          }}
        >
          {eyeOptions
            .filter((f) => f.value !== 3)
            .map((m) => (
              <Button.Basic
                text={m.text}
                key={m.value}
                style={{ marginRight: "8px" }}
                primary={m.value === eye}
                onClick={() => setEye(m.value)}
              />
            ))}
        </div>
      </React.Fragment>
    );
  }

  return (
    <div>
      {registryRequestId ? (
        <React.Fragment>
          <ProcedureJoinManagement rrId={registryRequestId} selectedEye={eye} />
          <div>
            <Button.Text
              text="I'm done here"
              onClick={() => showSuccess(true)}
            />
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <PatientForm staging onFormSubmit={onPatientSubmit} />
        </React.Fragment>
      )}
    </div>
  );
}
