import React, { useState, useEffect } from "react";
import axios from "axios";
import { Switch, Route } from "react-router-dom";
import styled from "styled-components";
import { useSelector } from "react-redux";

import { getUKTime } from "@util/getUKTime";

import { Wrapped } from "@components/Layouts";
import { Button, Notes, PageTitle, ViewError } from "@components/shared";
import { populateProcedure } from "./ProcedureDetail";
import {
  ProcedureList,
  ProcedureFormIops,
  ProcedureJoinManagement,
} from "@components/Procedure";
import { PostOpForm, PostOpList } from "@components/PostOp";
import { AuditManagement } from "@components/Audit";
import { procedureJoinKeys } from "@components/Procedure/ProcedureJoinManagement";
import { ComplicationManagement } from "@components/Complication";

import { getRoleInfo } from "@util/roleInfo";

const BtnWrapper = styled.div`
  display: flex;
  align-items: center;

  button + button {
    margin-left: 5px;
  }
`;

export default function PostOpDetail({ isAdmin, match }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [postOp, setPostOp] = useState(populatePostOp());
  const [isOpen, setOpen] = useState(false);
  const [procedure, setProcedure] = useState(null);

  const { id } = match.params;

  const roleInfo = getRoleInfo(useSelector((state) => state));

  useEffect(() => {
    fetchData();
  }, [JSON.stringify(match)]);

  useEffect(() => {
    fetchProcedure();
  }, [postOp.procedure_id]);

  useEffect(() => {
    fetchData();
  }, [isOpen]);

  function fetchData() {
    axios
      .get(`/post-op-visits/${id}/`)
      .then(({ data }) => {
        setLoading(false);
        setPostOp(populatePostOp(data.response[0]));
      })
      .catch((err) => {
        setError(err);
      });
  }

  function fetchProcedure() {
    if (!postOp.procedure_id) return;

    axios
      .get(`/procedures/${postOp.procedure_id}/`)
      .then(({ data }) => {
        setProcedure(populateProcedure(data.response[0]));
      })
      .catch((err) => {
        setProcedure(null);
      });
  }

  if (error) {
    return <ViewError text={error} />;
  }

  const PostopEdit = () => {
    const FORM_VIEW = "form";
    const JOIN_VIEW = "join";
    const [editView, setEditView] = useState(FORM_VIEW);

    return (
      <React.Fragment>
        {roleInfo.admin_research_fellow && (
          <div style={{ marginBottom: "1em" }}>
            <BtnWrapper>
              {[
                { text: "Visit Details", value: FORM_VIEW },
                { text: "Visit Associations", value: JOIN_VIEW },
              ].map((m, i) => (
                <Button.Tab
                  key={i}
                  text={m.text}
                  active={m.value === editView}
                  onClick={() => setEditView(m.value)}
                />
              ))}
            </BtnWrapper>
          </div>
        )}
        {editView === FORM_VIEW && (
          <React.Fragment>
            <ProcedureFormIops
              items={postOp.iop_entries}
              postopId={id}
              fetchData={fetchData}
              iop={postOp.iop}
            />
            {roleInfo.admin_research_fellow && (
              <PostOpForm
                id={id}
                postOpData={postOp}
                onSuccess={fetchData}
                procedureDate={postOp.procedure_date}
              />
            )}
          </React.Fragment>
        )}
        {editView === JOIN_VIEW && (
          <React.Fragment>
            <PostOpList items={[postOp]} showLink={false} />
            <ProcedureJoinManagement
              postOpId={id}
              detailMode
              filters={[
                procedureJoinKeys.complication,
                procedureJoinKeys.cataract_status,
                procedureJoinKeys.retinal_finding,
                procedureJoinKeys.sle_finding,
                // procedureJoinKeys.systemic_medications,
                procedureJoinKeys.ocular_medications,
                // procedureJoinKeys.gonioscopy,
              ]}
              selectedEye={postOp.eye}
            />
          </React.Fragment>
        )}
        <Button.Text
          text="I'm done editing"
          onClick={() => setOpen(false)}
          style={{ color: "#8e2a8c" }}
        />
      </React.Fragment>
    );
  };

  return (
    <div>
      <PageTitle
        title={`${postOp.variety_formatted} Visit`}
        links={
          isAdmin
            ? [
                { text: "Detail", to: match.url },
                { text: "Audits", to: `${match.url}/audits`, admin: true },
              ].map((l, i) => ({ ...l, key: i + 1 }))
            : []
        }
        subtitle={
          <React.Fragment>
            <Button.Link
              to={`/procedures/${postOp.procedure_id}`}
              text="View Procedure"
            />
          </React.Fragment>
        }
      />
      {procedure && !isOpen && (
        <Wrapped title="Procedure Details">
          <ProcedureList procedures={[procedure]} />
        </Wrapped>
      )}
      <Switch>
        <Route path={match.path} exact>
          <React.Fragment>
            <Wrapped
              title={
                roleInfo.admin_research_fellow || roleInfo.user_doctor
                  ? "Edit Visit"
                  : "Visit Details"
              }
              button={
                roleInfo.admin_research_fellow || roleInfo.user_doctor ? (
                  <Button.Toggle
                    edit
                    onClick={() => setOpen(!isOpen)}
                    open={isOpen}
                    secondary
                  />
                ) : null
              }
            >
              {isOpen ? (
                <PostopEdit />
              ) : (
                <React.Fragment>
                  <PostOpList items={[postOp]} showLink={false} />
                </React.Fragment>
              )}
            </Wrapped>
            {!roleInfo.admin_research_fellow && (
              <Wrapped title="Medications">
                <ProcedureJoinManagement
                  postOpId={id}
                  detailMode
                  viewOnly={!roleInfo.user_doctor && !roleInfo.user_proxy}
                  fetchData={fetchData}
                  filters={[
                    // procedureJoinKeys.systemic_medications,
                    procedureJoinKeys.ocular_medications,
                    procedureJoinKeys.cataract_status,
                    procedureJoinKeys.retinal_finding,
                    procedureJoinKeys.sle_finding,
                  ]}
                  selectedEye={postOp.eye}
                />
              </Wrapped>
            )}
            <ComplicationManagement
              postopId={id}
              minDate={postOp.procedure_date}
            />
          </React.Fragment>
        </Route>
        {isAdmin && (
          <Route path={`${match.path}/audits`}>
            <Wrapped>
              <AuditManagement
                reqInfo={{ post_op_visit_id: id }}
                updateInfo={{
                  xhr: (d) => axios.put(`/post-op-visits/${id}/`, d),
                  req: { id: id },
                }}
                onUpdate={fetchData}
              />
            </Wrapped>
          </Route>
        )}
      </Switch>
      <Notes
        additionalCreateData={{ post_op_visit_id: id }}
        additionalFetchData={{ post_op_visit_id: id }}
        title="Visit Notes"
      />
    </div>
  );
}

export function populatePostOp(data = {}) {
  return {
    axis: data.axis || "",
    bcva_method: data.bcva_method || null, // int
    bcva: data.bcva || "", // float: ;
    cylinder: data.cylinder || "",
    eye: data.eye || null,
    iop_entries: data.iop_entries || [
      { variety: 1, time: getUKTime(), value: "" },
    ],
    iop_variety: data.iop_variety || "", // int
    iop: data.iop || "", // float
    join_names: data.join_names || [],
    mean_deviation: data.mean_deviation || "",
    oct: data.oct || "",
    patient_discomfort: data.patient_discomfort || null, // int
    post_op_date: data.post_op_date ? new Date(data.post_op_date) : null,
    procedure_id: data.procedure_id,
    procedure_date: data.procedure_date ? new Date(data.procedure_date) : null,
    psd: data.psd || "",
    sphere: data.sphere || "",
    status: data.status, // int
    treatment_related_symptoms: data.treatment_related_symptoms || false, // bool
    variety: data.variety || null, // int
    variety_formatted: data.variety_formatted || "",
    vfi: data.vfi || "",
  };
}
