import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import reduceReducers from "reduce-reducers";
import initialState from "./initialState";

import adminReducer from "./admin";
import userReducer from "./user";
import loadingReducer from "./loading";

const rootReducer = reduceReducers(
  initialState,
  adminReducer,
  userReducer,
  loadingReducer
);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  rootReducer,
  composeEnhancers()
  // composeEnhancers(
  //   applyMiddleware(
  //     createSentryMiddleware(Sentry),
  //     thunk
  //   ),
  // )
);
