import React from "react";
import axios from "axios";
import toast from "react-hot-toast";

import { Button } from "@components/shared";

export default function RegistryRequestApproval({ id, fetchData }) {
  function onClick(approved) {
    const req = {
      registry_request_id: id,
      approved,
    };

    axios
      .post(`/registry-requests/${id}/approval/`, req)
      .then(() => {
        fetchData();
      })
      .catch((err) => {
        toast.error(err);
      });
  }
  return (
    <div style={{ display: "flex" }}>
      <Button.Basic
        text="Reject"
        secondary
        onClick={() => onClick(false)}
        style={{ marginRight: "8px" }}
      />
      <Button.Basic text="Accept" primary onClick={() => onClick(true)} />
    </div>
  );
}
