import React, { useState } from "react";
import { useSelector } from "react-redux";

import { PatientCreate, Table } from "@components/Patient";
import { Wrapped } from "@components/Layouts";
import { Button } from "@components/shared";

import { getRoleInfo } from "@util/roleInfo";

export default function PatientsIndex({ history }) {
  const [isOpen, setOpen] = useState(false);

  const roleInfo = getRoleInfo(useSelector((state) => state));

  return (
    <div>
      <Wrapped
        title="Patients"
        button={
          roleInfo.user_doctor || roleInfo.user_proxy ? (
            <Button.Toggle
              closedText="Add Patients"
              onClick={() => setOpen(!isOpen)}
              open={isOpen}
            />
          ) : null
        }
      >
        {isOpen ? (
          <PatientCreate
            onSuccess={(newPatientId) => {
              setOpen(false);
              history.push(`/patients/${newPatientId}`);
            }}
          />
        ) : (
          <Table />
        )}
      </Wrapped>
    </div>
  );
}
