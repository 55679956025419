import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";

import {
  BasicGrid,
  Card,
  Button,
  Icon,
  JoinLabels,
  EmptyList,
} from "@components/shared";

import { PatientPracticeSelect } from "@components/Patient";

import formatEye from "@util/formatEye";
import { getUuid } from "@util/getUuid";
import { varietyOptions as visitOptions } from "@components/PostOp/postOpOptions";
import { bcvaFormatted } from "./procedureOptions";
import { size } from "@util/breakpoints";

const iconLinkSize = 24;

export default function ProcedureList({
  isAdmin,
  patientId,
  procedures,
  showLink,
  showPatientLink,
  retrievalData,
  fetchDetailData,
}) {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [check, setCheck] = useState(false);
  const [selectedPracticeId, setSelectedPracticeId] = useState(null);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (!retrievalData) return;

    fetchData();
  }, [selectedPracticeId]);

  useEffect(() => {
    if (items) {
      setItems(procedures);
    }
  }, [JSON.stringify(procedures)]);

  function fetchData() {
    setLoading(true);

    const req = {
      ...retrievalData.req,
      status: 1,
    };

    if (selectedPracticeId) {
      req.practice_id = selectedPracticeId;
    }

    axios
      .post(retrievalData.url, req)
      .then(({ data }) => {
        setLoading(false);
        setTotal(data.response[0].total);
        setItems(data.response[0].data.map((m) => ({ ...m, uuid: getUuid() })));
      })
      .catch((err) => {
        setLoading(false);
        setItems([]);
      });
  }

  function commitProcedureCheck(proId) {
    const id = proId;

    if (!check) {
      setCheck(true);
    } else {
      commitProcedure(id);
    }
  }

  function commitProcedure(procedureId) {
    if (fetchDetailData) {
      axios
        .get(`/procedures/${procedureId}/commit/`)
        .then(() => {
          toast.success("Committed!");
          setCheck(false);
          fetchDetailData();
        })
        .catch((err) => {
          toast.error(err);
          setCheck(false);
          fetchDetailData();
        });
    } else {
      axios
        .get(`/procedures/${procedureId}/commit/`)
        .then(() => {
          toast.success("Committed!");
          setCheck(false);
          fetchData();
        })
        .catch((err) => {
          toast.error(err);
          setCheck(false);
          fetchData();
        });
    }
  }
  return (
    <React.Fragment>
      {/* {loading && <p>Fetching procedures...</p>} */}
      {patientId && isAdmin && (
        <div style={{ marginBottom: "1em" }}>
          <PatientPracticeSelect
            patientId={patientId}
            value={selectedPracticeId}
            onChange={(e) => setSelectedPracticeId(e)}
            label="Filter procedures by practice"
            showAll
          />
        </div>
      )}
      {!loading && items.length === 0 && (
        <EmptyList text="No procedures yet..." />
      )}
      <BasicGrid columns={1}>
        {items.map((item, i) => (
          <Card.Wrapper key={item.uuid}>
            <Card.Meta
              canCommit={item.can_commit}
              isCommitted={item.committed_to_registry}
              hasActions={showLink || showPatientLink}
              description={`${item.formatted_patient_name} - ${item.age} year old ${item.ethnicity} ${item.gender}`}
              title={item.operation_date_formatted}
              location={
                isAdmin ? (
                  <Button.Link
                    to={`/practices/${item.practice_id}`}
                    text={item.practice_name}
                  />
                ) : (
                  item.practice_name
                )
              }
              stats={[
                {
                  title: "Eye",
                  value: formatEye(item.eye),
                },
                { title: "IOP", value: item.iop ? `${item.iop}mmHg` : "--" },
                {
                  title: "BCVA",
                  value: bcvaFormatted(item.bcva, item.bcva_method),
                },
                // {
                //   title: "Axial Length",
                //   value: item.axial_length ? item.axial_length : "--",
                // },
                {
                  title: "Power",
                  value: item.power ? `${item.power}mW` : "--",
                },
                {
                  title: "Duration",
                  value: item.duration ? `${item.duration}ms` : null,
                },
                {
                  title: "Spots",
                  value: item.number_of_spots
                    ? `${item.number_of_spots}`
                    : null,
                },
                {
                  title: "Sweep Speed Velocity",
                  value: item.sweep_velocity_formatted
                    ? item.sweep_velocity_formatted
                    : null,
                },
                {
                  title: "Sweeps",
                  value: item.sweep_count_formatted
                    ? item.sweep_count_formatted
                    : null,
                },
              ].filter((f) => f.value)}
            />
            <Card.Content>
              {item.join_names && item.join_names.length > 0 && (
                <BasicGrid columns={2}>
                  {item.join_names
                    .filter((f) => f.joins.length)
                    .map((m, i) => (
                      <JoinLabels
                        key={getUuid()}
                        title={m.title}
                        joins={m.joins.map((mm, ii) => ({
                          text: `${mm.text}${
                            mm.description ? ` ${mm.description}` : ""
                          }`,
                          id: getUuid(),
                        }))}
                      />
                    ))}
                </BasicGrid>
              )}
              {item.procedure_comorbidities &&
                item.procedure_comorbidities.length > 0 && (
                  <BasicGrid columns={2} style={{ marginTop: "0" }}>
                    <JoinLabels
                      title="Comorbidities"
                      joins={item.procedure_comorbidities.map((m) => ({
                        text: `${m.name}${
                          m.description ? ` - ${m.description}` : ""
                        }`,
                        id: m.id,
                      }))}
                    />
                  </BasicGrid>
                )}
              <VisitsDisplay visits={item.post_op_visits_array || []} />
            </Card.Content>
            <Card.Actions>
              {item.can_commit && !item.committed_to_registry && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "fit-content",
                    margin: "10px auto",
                  }}
                >
                  {check && (
                    <p
                      style={{
                        fontWeight: "bold",
                        fontSize: "15px",
                        color: "#f2f2f2",
                      }}
                    >
                      Warning: You will not be able to edit this once it is
                      committed.
                    </p>
                  )}
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Button.Basic
                      text={!check ? "Commit to registry" : "Yes, I'm sure"}
                      onClick={() => commitProcedureCheck(item.id)}
                      style={{
                        color: check ? "#12b370" : "#fff",
                        fontWeight: "bold",
                        border: "none",
                        margin: "0 auto",
                      }}
                    />
                    {check && (
                      <Button.Basic
                        text={"Nevermind!"}
                        onClick={() => setCheck(false)}
                        style={{
                          color: "#fff",
                          fontWeight: "normal",
                          border: "none",
                          margin: "0 auto",
                        }}
                      />
                    )}
                  </div>
                </div>
              )}
              {(showLink || (showPatientLink && item.patient_id)) && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  {showLink && (
                    <Button.Link
                      to={`/procedures/${item.id}`}
                      text={<Icon.Eye size={27} />}
                      title="View Procedure"
                    />
                  )}
                  {showPatientLink && item.patient_id && (
                    <Button.Link
                      to={`/patients/${item.patient_id}`}
                      text={<Icon.User size={27} />}
                      title="View Patient"
                    />
                  )}
                </div>
              )}
            </Card.Actions>
          </Card.Wrapper>
        ))}
      </BasicGrid>
    </React.Fragment>
  );
}

ProcedureList.defaultProps = {
  isAdmin: false,
  procedures: [],
  showLink: true,
  showPatientLink: true,
};

const VisitsWrapper = styled.div`
  display: flex;
  align-items: center;
  text-shadow: 2px 2px 2px #333;

  @media (max-width: ${size.tablet}) {
    display: none;
  }
`;

const VisitsDisplay = ({ visits }) => {
  const visitVarieties = visits.map((v) => v.variety);

  return (
    <VisitsWrapper>
      {visitOptions.map((v, i) => (
        <VisitDisplay
          key={i + 1}
          active={visitVarieties.includes(v.value)}
          visitId={
            visitVarieties.includes(v.value) &&
            visits.find((f) => f.variety === v.value).id
              ? visits.find((f) => f.variety === v.value).id
              : null
          }
          text={v.text
            .replace("Day", "D")
            .replace("Week", "W")
            .replace("Months", "M")
            .replace("Month", "M")
            .replace(" ", "")}
        />
      ))}
    </VisitsWrapper>
  );
};

VisitsDisplay.defaultProps = {
  visits: [],
};

const VisitWrapper = styled.div`
  color: #fff;
  font-weight: bold;
  opacity: ${(props) => (props.active ? 1 : 0.25)};

  a {
    color: #fff;
  }

  & + & {
    margin-left: 10px;
  }
`;

const VisitDisplay = ({ text, active, visitId }) => {
  return (
    <VisitWrapper active={active}>
      {visitId ? <Link to={`/post-op-visits/${visitId}`}>{text}</Link> : text}
    </VisitWrapper>
  );
};

VisitDisplay.defaultProps = {
  text: "",
  active: "",
};
