import React from "react";
import styled from "styled-components";
import { FaTrashAlt } from "react-icons/fa";

import { Icon } from "@components/shared";
import BigInput from "./BigInput";
import LabelActionButton from "./LabelActionButton";

export default function MinMaxFields({
  fieldTitle,
  minValue,
  maxValue,
  onMinChange,
  onMaxChange,
  onClearClick,
}) {
  return (
    <Wrapper disabled={!minValue && !maxValue}>
      <div className="min-max-meta">
        <p>{fieldTitle} </p>
        <LabelActionButton
          buttonProps={{
            onClick: () => onClearClick(),
            title: "Remove",
            opacity: !minValue && !maxValue ? "0.5" : "1",
            disabled: !minValue && !maxValue,
          }}
        >
          <FaTrashAlt color="#d9d9d9" />
        </LabelActionButton>
      </div>
      <InputWrapper>
        <BigInput
          inputProps={{
            placeholder: "Min",
          }}
          error={maxValue && minValue && minValue > maxValue}
          value={minValue}
          onChange={onMinChange}
        />
        <span>to</span>
        <BigInput
          value={maxValue}
          onChange={onMaxChange}
          error={maxValue && minValue && maxValue < minValue}
          inputProps={{
            placeholder: "Max",
          }}
        />
      </InputWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-bottom: 1em;

  .min-max-meta {
    display: flex;
    align-items: center;
    margin-bottom: 0.65em;

    p {
      margin-bottom: 0;
      margin-right: 8px;
      font-size: 1.25em;
      font-weight: bold;
    }

    button {
      font-family: inherit;
      border: none;
      padding: 0;
      outline: none;
      background: none;
      font-size: 20px;
      cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
      position: relative;
      top: 2px;

      &:disabled {
        opacity: 0.25;
      }
    }
  }
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 250px;

  span {
    margin: 0 15px;
    font-weight: bold;
  }
`;
