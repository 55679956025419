import React, { useState } from "react";
import axios from "axios";

import { Form } from "semantic-ui-react";
import { Button } from "@components/shared";

const defaultNhsNumber = process.env.ENV === "dev" ? "456567" : "";

export default function NhsLookup({ onLookupSuccess, onLookupFail }) {
  const [value, setValue] = useState(defaultNhsNumber);
  const [loading, setLoading] = useState(false);

  function onSubmit() {
    setLoading(true);

    axios
      .post(`/nhs-lookup/`, { number: value.trim() })
      .then(({ data }) => {
        setLoading(false);

        if (!data.response.length) {
          return onLookupFail(value);
        } else {
          if (data.response[0].registry_request) {
            window.location = `/registry-requests/${data.response[0].registry_request_id}`;
          } else {
            return onLookupSuccess(data.response[0]);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  return (
    <div>
      <Form>
        <Form.Input
          label="Patient ID"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </Form>
      <Button.Basic
        type="submit"
        primary
        text="Submit"
        style={{ marginTop: "1em" }}
        disabled={!value || loading}
        onClick={onSubmit}
        loading={loading}
      />
    </div>
  );
}
