import {
  bcvaMethodOptions as bcvaMethods,
  bcvaOptions as bcvaOpts,
  iopVarietyOptions as iopVarietyOpts,
} from "@components/Procedure/procedureOptions";

export const bcvaMethodOptions = [...bcvaMethods];

export const iopVarietyOptions = [...iopVarietyOpts];

export const bcvaOptions = bcvaOpts;

export const statusOptions = [
  { value: 1, text: "active" },
  { value: 2, text: "inactive" },
  { value: 3, text: "deleted" },
];

export const discomfortOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(
  (m) => ({
    key: m,
    text: m,
    value: m,
  })
);

export const varietyOptions = [
  "1 Day",
  "1 Week",
  "1 Month",
  "3 Month",
  "6 Month",
  "9 Month",
  "12 Month",
  "15 Month",
  "18 Month",
  "21 Month",
  "24 Month",
  "27 Month",
  "30 Month",
  "33 Month",
  "36 Month",
].map((m, i) => ({ key: i + 1, value: i + 1, text: m }));
