import React from "react";
import styled from "styled-components";

const TextButtonStyle = styled.button`
  margin-top: 1.5em;
  padding: 0;
  border: none;
  outline: none;
  font-family: inherit;
  cursor: pointer;
  background: none;
  font-weight: bold;
  color: ${(props) => (props.color ? props.color : "inherit")};

  &:disabled {
    cursor: not-allowed;
    opacity: 0.25;
  }
`;

const TextButton = ({ onClick, text, style, disabled, color }) => (
  <TextButtonStyle
    style={style}
    onClick={onClick}
    disabled={disabled}
    color={color}
  >
    {text}
  </TextButtonStyle>
);

export default TextButton;

TextButton.defaultProps = {
  disabled: false,
  style: {},
};
