import React from "react";
import styled from "styled-components";

const Input = styled.input`
  font-family: inherit !important;
  font-size: 24px !important;
  font-weight: bold !important;
  background: #f7f7f7 !important;
  color: #333333 !important;
  border-radius: 3px;
  padding: 10px !important;
  border-color: ${(props) =>
    props.error ? "var(--negativeColor)" : "rgba(34,36,38,.15)"} !important;
`;

export default function BigInput({ onChange, value, inputProps, error }) {
  return (
    <Input
      onChange={(e) => onChange(e.target.value)}
      value={value}
      error={error}
      {...inputProps}
    />
  );
}

BigInput.defaultProps = {
  error: false,
  inputProps: {},
};
