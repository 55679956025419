import React from "react";
import styled from "styled-components";

import { BasicGrid, Button } from "@components/shared";

import { visitOptions, visitValues } from "./queryContainerOptions";

export default function Ranges({ values, visitMode, onChange, onModeChange }) {
  function onClick(visitValue) {
    // return onChange([visitValue]);

    if (visitMode === visitValues.consistent)
      return onCohortVisitClick(visitValue);
    if (visitMode === visitValues.non_consistent) return onChange([visitValue]);
    // return onNonCohortVisitClick(visitValue);
    if (visitMode === visitValues.baseline) {
      onChange([visitValue]);
    }
  }

  function onCohortVisitClick(visitValue) {
    let newData = [...values];

    if (newData.includes(visitValue)) {
      newData = newData.filter((f) => f !== visitValue);
    } else {
      newData.push(visitValue);
    }

    const validation = cohortValidation(newData, true);

    onChange(validation);
  }

  function onNonCohortVisitClick(visitValue) {
    if (values.includes(visitValue)) {
      onChange([...values].filter((f) => f !== visitValue));
    } else {
      onChange([...values, visitValue]);
    }
  }

  return (
    <div>
      <Title
        text={
          visitMode === visitValues.consistent
            ? "Consistent Cohort"
            : visitMode === visitValues.non_consistent
            ? "Non-Consistent Cohort"
            : "Baseline"
        }
        visitCount={values.length}
        onAllClick={() => onChange(visitOptions.map((m) => m.value))}
        onRemoveAllClick={() => onChange([])}
        visitMode={visitMode}
        onModeChange={(e) => {
          onModeChange(e);

          onChange([]);
          // if ([visitValues.consistent, visitValues.baseline,].includes(e)) {
          // onChange([]);
          // }
        }}
      />
      <BasicGrid columns={4}>
        {visitOptions.map((v) => (
          <Button.Basic
            key={v.value}
            text={v.name}
            onClick={() => onClick(v.value)}
            primary={values.includes(v.value)}
          />
        ))}
      </BasicGrid>
    </div>
  );
}

Ranges.defaultProps = {
  values: [],
  text: "Ranges",
};

const Title = ({
  text,
  visitCount,
  visitMode,
  onAllClick,
  onRemoveAllClick,
  onModeChange,
}) => (
  <StyledTitle>
    <div className="meta">
      <p>
        <b>{text}</b>
      </p>
      {visitMode === visitValues.consistent && (
        <React.Fragment>
          <button
            onClick={onAllClick}
            disabled={
              visitCount === visitOptions.length ||
              visitMode === visitValues.baseline
            }
          >
            Select all visits
          </button>
          <button
            disabled={visitCount === 0 || visitMode === visitValues.baseline}
            onClick={onRemoveAllClick}
          >
            Remove all visits
          </button>
        </React.Fragment>
      )}
    </div>
    <div className="modes">
      {modeBtns.map((m) => (
        <button
          key={m.value}
          onClick={() => onModeChange(m.value)}
          disabled={visitMode === m.value}
        >
          Switch to {m.text}
        </button>
      ))}
    </div>
  </StyledTitle>
);

const modeBtns = Object.keys(visitValues).map((m, i) => ({
  value: visitValues[m],
  text: visitValues[m],
}));

const StyledTitle = styled.div`
  margin-bottom: 1.5em;
  .meta {
    display: flex;
    align-items: center;

    p {
      margin: 0 5px 0 0;
    }
  }

  button {
    color: var(--greyText);
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 0;
    font-weight: bold;
    font-size: 12px;

    & + button {
      margin-left: 8px;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.25;
    }
  }

  .modes {
    margin-top: 0.5em;
  }
`;

function cohortValidation(visits, cohort) {
  const vals = visitOptions.map((m) => m.value);

  if (cohort) {
    let visitArr = visits.sort((a, b) => a - b);

    const startInt = visitArr[0];
    const endInt = visitArr[visitArr.length - 1];

    const startRangeIndex = vals.findIndex((v) => v === startInt);
    const endRangeIndex = vals.findIndex((v) => v === endInt);

    if (startRangeIndex !== endRangeIndex) {
      const cohortVisits = vals.splice(
        startRangeIndex,
        endRangeIndex - startRangeIndex + 1
      );
      return cohortVisits;
    }
  }

  return visits;
}
