import React from "react";
import { PaginatedTable, Button } from "@components/shared";

export default function Table() {
  return (
    <React.Fragment>
      <PaginatedTable
        columns={[
          {
            Header: "",
            width: 50,
            id: "view",
            accessor: (d) => <Button.View url={`/doctors/${d.id}`} />,
          },
          { Header: "Name", accessor: "full_name" },
        ].map((m) => ({ ...m, sortable: false }))}
        entityName="Doctors"
        keyName="data"
        url="/retrieve-doctors/"
      />
    </React.Fragment>
  );
}
