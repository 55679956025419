import React, { useState, useEffect } from "react";
import { Form } from "semantic-ui-react";

import { BasicGrid, Button, DateField, OptionButton } from "@components/shared";
import { eyeOptions } from "@util/eyeOptions";
import { medFrequencyOptions } from "./procedureOptions";

const ProcedureJoinForm = ({
  editData,
  editing,
  isCataractStatus,
  isDiagnosis,
  isGonioscopy,
  isMeds,
  isPos,
  isRetinalFinding,
  onAddClick,
  options,
  selectedEye,
}) => {
  const [selected, setSelected] = useState(null);
  const [formData, setFormData] = useState(populateFormData());

  const selectedObj = options.find((f) => f.id === selected) || editData;

  useEffect(() => {
    setFormData(
      populateFormData({ ...editData, eye: editData.eye || selectedEye })
    );
  }, [JSON.stringify(editData)]);

  if (!options.length && !editing) return null;

  function onAddEval() {
    const req = {
      id: selected,
    };

    Object.keys(formData).forEach((k) => {
      if (!formData[k]) return;

      req[k] = formData[k];
    });

    if (editData.first_diagnosed_at) {
      req.first_diagnosed_at = formData.date;
    }

    onAddClick(req);
    if (!editing) {
      setSelected(null);
      setFormData(populateFormData({ eye: selectedEye }));
    }
  }

  return (
    <div className="add-mgmt">
      {options.length > 0 && (
        <React.Fragment>
          <h4>Additional Options</h4>
          <BasicGrid>
            {options.map((opt) => (
              <OptionButton
                dateRemoved={opt.date_removed}
                key={opt.id}
                text={opt.name}
                onClick={() => setSelected(selected === opt.id ? null : opt.id)}
                active={selected === opt.id}
              />
            ))}
          </BasicGrid>
        </React.Fragment>
      )}
      {/* {selected !== null && !formData.eye && ( */}
      {editing && (
        <React.Fragment>
          <h4>Which Eye?</h4>
          <BasicGrid>
            {eyeOptions.map((m, i) => (
              <OptionButton
                key={m.value}
                text={m.text}
                active={formData.eye === m.value}
                onClick={() => setFormData({ ...formData, eye: m.value })}
              />
            ))}
          </BasicGrid>
        </React.Fragment>
      )}
      {selectedObj &&
        selectedObj.value_array &&
        selectedObj.value_array.length > 0 && (
          <React.Fragment>
            <h4>Value?</h4>
            <BasicGrid>
              {selectedObj.value_array.map((m, i) => (
                <OptionButton
                  key={i + 1}
                  text={m.text}
                  active={m.value === formData.value}
                  onClick={() => setFormData({ ...formData, value: m.value })}
                />
              ))}
            </BasicGrid>
          </React.Fragment>
        )}
      {((editData && editData.spaeth_angle_grade) ||
        (editData && editData.nerve && editData.dropdown_data) ||
        (editData && editData.trace && editData.dropdown_data) ||
        (editData && editData.dropdown_data && editData.spaeth_angle_grade) ||
        (selectedObj &&
          ((isGonioscopy && selectedObj.spaeth_angle_grade) ||
            (isRetinalFinding && selectedObj.nerve) ||
            (isCataractStatus && selectedObj.ns)))) && (
        <React.Fragment>
          <Form style={{ marginTop: "1em" }}>
            {selectedObj.dropdown_data.data.map((d) => (
              <Form.Select
                key={d.position}
                label={d.label}
                value={formData[d.name]}
                placeholder="Select one..."
                options={d.options.map((opt) => ({
                  key: opt.value,
                  value: opt.value,
                  text: opt.text,
                }))}
                onChange={(e, opt) =>
                  setFormData({ ...formData, [d.name]: opt.value })
                }
              />
            ))}
          </Form>
        </React.Fragment>
      )}
      {(((isDiagnosis || isPos) && selected !== null) ||
        (editing && formData.date)) && (
        <React.Fragment>
          <h4>{isDiagnosis ? "Date Diagnosed" : "Surgery Date"}</h4>
          <Form>
            <DateField
              selected={formData.date}
              onChange={(e) => setFormData({ ...formData, date: e })}
              maxDate={new Date()}
            />
          </Form>
        </React.Fragment>
      )}
      {isMeds && (
        <Form style={{ marginTop: "1em" }}>
          <Form.Select
            label={"Frequency"}
            value={formData.frequency}
            placeholder="Select one..."
            options={medFrequencyOptions}
            onChange={(e, opt) =>
              setFormData({ ...formData, frequency: opt.value })
            }
          />
        </Form>
      )}
      {selected && (
        <Button.Basic
          disabled={
            !selected ||
            !formData.eye ||
            (isMeds && !formData.frequency) ||
            (isPos && !formData.date) ||
            // ((isDiagnosis || isPos) && !formData.date) ||
            (selectedObj &&
              selectedObj.value_array &&
              selectedObj.value_array.length > 0 &&
              !formData.value) ||
            (isRetinalFinding &&
              selectedObj.nerve &&
              !formData.nerve_variety) ||
            (isCataractStatus && selectedObj.ns && !formData.trace) ||
            (isGonioscopy &&
              selectedObj.spaeth_angle_grade &&
              (!formData.iris_insertion ||
                !formData.angular_approach ||
                !formData.peripheral_iris ||
                !formData.meshwork))
          }
          onClick={onAddEval}
          style={{ marginTop: "1em" }}
          text="Add"
          type="submit"
          primary
        />
      )}
      {editing && (
        <Button.Basic
          style={{ marginTop: "1.5em" }}
          text="Update"
          primary
          onClick={onAddEval}
        />
      )}
    </div>
  );
};

ProcedureJoinForm.defaultProps = {
  editData: {},
  options: [],
};

export default ProcedureJoinForm;

function populateFormData(values = {}) {
  return {
    angular_approach: values.angular_approach || null,
    date: values.first_diagnosed_at
      ? new Date(values.first_diagnosed_at)
      : values.date
      ? new Date(values.date)
      : null,
    eye: values.eye || null,
    frequency: values.frequency || null,
    iris_insertion: values.iris_insertion || null,
    meshwork: values.meshwork || null,
    nerve_variety: values.nerve_variety || null,
    peripheral_iris: values.peripheral_iris || null,
    trace: values.trace || null,
    value: values.value || null,
  };
}
