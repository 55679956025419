export function getUKTime() {
  const now = new Date();
  const today = new Date();
  const dstStart = new Date(Date.UTC(today.getUTCFullYear(), 2, 31, 1));
  const dstEnd = new Date(Date.UTC(today.getUTCFullYear(), 9, 25, 1));

  let ukHours;
  if (
    today.getTime() >= dstStart.getTime() &&
    today.getTime() < dstEnd.getTime()
  ) {
    ukHours = now.getUTCHours() + 1; // BST (GMT+1)
  } else {
    ukHours = now.getUTCHours(); // GMT (UTC+0)
  }

  let minutes = now.getUTCMinutes();
  const hours = ukHours < 10 ? "0" + ukHours : ukHours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  return `${hours}:${minutes}`;
}
