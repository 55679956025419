import React, { useState } from "react";
import { connect } from "react-redux";
import { UserManagement } from "@components/PracticeUser";

function PracticeUsersIndex() {
  const [isOpen, setOpen] = useState(false);

  return <UserManagement />;
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(PracticeUsersIndex);
