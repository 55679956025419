import React, { useState, useEffect } from "react";
import axios from "axios";
import { Divider, Form } from "semantic-ui-react";
import toast from "react-hot-toast";

import { Button, DateField, NumberField, Text } from "@components/shared";
import { ProcedureFormIops } from "@components/Procedure";
import {
  bcvaOptions,
  bcvaMethodOptions,
  iopVarietyOptions,
  statusOptions,
  discomfortOptions,
  varietyOptions,
} from "./postOpOptions";

import { populatePostOp } from "@pages/user/PostOpDetail";

export default function PostOpForm({
  existingVisits,
  id,
  onSuccess,
  postOpData,
  procedureDate,
  procedureId,
}) {
  const [postOp, setPostOp] = useState(populatePostOp());
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (postOpData) {
      setPostOp(populatePostOp(postOpData));
    }
  }, [JSON.stringify(postOpData)]);

  function onSubmit() {
    setError(null);
    setLoading(true);

    if (id) return onUpdate();

    return onCreate();
  }

  function onCreate() {
    const req = {
      ...postOp,
      procedure_id: procedureId,
      status: 1,
    };

    axios
      .post(`/post-op-visits/`, req)
      .then(({ data }) => {
        setLoading(false);
        onSuccess(data.response[0]);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  function onUpdate() {
    const req = {
      ...postOp,
      id,
    };

    delete req.iop_entries;

    axios
      .put(`/post-op-visits/${id}/`, req)
      .then(() => onSuccess())
      .then(() => toast.success("Visit Updated!"))
      .then(() => setLoading(false))
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <React.Fragment>
      <Form>
        <Form.Field>
          <label>Visit Date</label>
          <DateField
            selected={postOp.post_op_date}
            onChange={(e) => setPostOp({ ...postOp, post_op_date: e })}
            maxDate={new Date()}
            minDate={procedureDate}
          />
        </Form.Field>
        {id && (
          <Form.Select
            label="Status"
            value={postOp.status}
            options={statusOptions}
            search
            placeholder="Select one..."
            onChange={(e, d) => setPostOp({ ...postOp, status: d.value })}
          />
        )}
        {!id && (
          <Form.Select
            label="Variety"
            value={postOp.variety}
            options={varietyOptions.map((m) => ({
              ...m,
              disabled: existingVisits.includes(m.value),
              description: existingVisits.includes(m.value)
                ? "Visit already entered"
                : null,
            }))}
            search
            placeholder="Select one..."
            onChange={(e, d) => setPostOp({ ...postOp, variety: d.value })}
          />
        )}
        <Form.Select
          label="Patient Discomfort (0-10, 10 being severe)"
          value={postOp.patient_discomfort}
          options={discomfortOptions}
          search
          placeholder="Select one..."
          onChange={(e, d) =>
            setPostOp({ ...postOp, patient_discomfort: d.value })
          }
        />
        {!id && (
          <React.Fragment>
            <ProcedureFormIops
              creating
              items={postOp.iop_entries}
              onChange={(e) =>
                setPostOp({
                  ...postOp,
                  iop_entries: e,
                })
              }
            />
            <Divider />
          </React.Fragment>
        )}
        <Form.Group widths="equal">
          <Form.Select
            label="IOP Variety"
            value={postOp.iop_variety}
            options={iopVarietyOptions}
            search
            placeholder="Select one..."
            onChange={(e, d) => setPostOp({ ...postOp, iop_variety: d.value })}
          />
          {/* <Form.Field>
            <label>IOP (0-70 mmHg)</label>
            <NumberField
              value={postOp.iop}
              decimalScale={2}
              onValueChange={(e) =>
                setPostOp({ ...postOp, iop: e.formattedValue })
              }
              isAllowed={(values) => {
                const { floatValue } = values;
                return (
                  (floatValue >= 0 && floatValue <= 70) ||
                  floatValue === undefined
                );
              }}
            />
          </Form.Field> */}
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Select
            label="BCVA Method"
            value={postOp.bcva_method}
            options={bcvaMethodOptions}
            search
            placeholder="Select one..."
            onChange={(e, d) => setPostOp({ ...postOp, bcva_method: d.value })}
          />
          <Form.Select
            label="BCVA"
            value={postOp.bcva}
            options={bcvaOptions(postOp.bcva_method)}
            search
            placeholder="Select one..."
            onChange={(e, d) => setPostOp({ ...postOp, bcva: d.value })}
          />
        </Form.Group>
        <h4>Refraction</h4>
        <Form.Group widths="equal">
          <Form.Field>
            <label>Sphere (-20-20)</label>
            <NumberField
              value={postOp.sphere}
              decimalScale={2}
              onValueChange={(e) =>
                setPostOp({ ...postOp, sphere: e.formattedValue })
              }
              isAllowed={(values) => {
                const { floatValue } = values;
                return (
                  (floatValue >= -20 && floatValue <= 20) ||
                  floatValue === undefined
                );
              }}
            />
          </Form.Field>
          <Form.Field>
            <label>Cylinder (-15-15)</label>
            <NumberField
              value={postOp.cylinder}
              decimalScale={2}
              onValueChange={(e) =>
                setPostOp({ ...postOp, cylinder: e.formattedValue })
              }
              isAllowed={(values) => {
                const { floatValue } = values;
                return (
                  (floatValue >= -15 && floatValue <= 15) ||
                  floatValue === undefined
                );
              }}
            />
          </Form.Field>
          <Form.Field>
            <label>Axis (0-180 deg)</label>
            <NumberField
              value={postOp.axis}
              decimalScale={0}
              onValueChange={(e) =>
                setPostOp({ ...postOp, axis: e.formattedValue })
              }
              isAllowed={(values) => {
                const { floatValue } = values;
                return (
                  (floatValue >= 0 && floatValue <= 180) ||
                  floatValue === undefined
                );
              }}
            />
          </Form.Field>
        </Form.Group>
        <h4>Visual Field</h4>
        <Form.Group widths="equal">
          <Form.Field>
            <label>Mean Deviation (-35-5)</label>
            <NumberField
              value={postOp.mean_deviation}
              decimalScale={2}
              onValueChange={(e) =>
                setPostOp({ ...postOp, mean_deviation: e.formattedValue })
              }
              isAllowed={(values) => {
                const { floatValue } = values;
                return (
                  (floatValue >= -35 && floatValue <= 5) ||
                  floatValue === undefined
                );
              }}
            />
          </Form.Field>
          <Form.Field>
            <label>VFI (0-100%)</label>
            <NumberField
              value={postOp.vfi}
              decimalScale={0}
              onValueChange={(e) =>
                setPostOp({ ...postOp, vfi: e.formattedValue })
              }
              isAllowed={(values) => {
                const { floatValue } = values;
                return (
                  (floatValue >= 0 && floatValue <= 100) ||
                  floatValue === undefined
                );
              }}
            />
          </Form.Field>
          <Form.Field>
            <label>PSD</label>
            <NumberField
              value={postOp.psd}
              decimalScale={0}
              onValueChange={(e) =>
                setPostOp({ ...postOp, psd: e.formattedValue })
              }
              isAllowed={(values) => {
                return true;
                // const { floatValue } = values;
                // return (
                //   (floatValue >= 0 && floatValue <= 180) ||
                //   floatValue === undefined
                // );
              }}
            />
          </Form.Field>
        </Form.Group>
        {/* <Form.Field>
          <label>Treatment Related Symptoms</label>
          <Form.Radio
            toggle
            checked={postOp.treatment_related_symptoms}
            label={postOp.treatment_related_symptoms ? "Yes" : "No"}
            onChange={() =>
              setPostOp({
                ...postOp,
                treatment_related_symptoms: !postOp.treatment_related_symptoms,
              })
            }
          />
        </Form.Field> */}
        {error && <Text.Error text={error} />}
        <Button.Basic
          type="submit"
          disabled={
            !postOp.post_op_date ||
            !postOp.variety ||
            postOp.patient_discomfort === null ||
            (!id && !postOp.iop_entries[0].value) ||
            (!id && !postOp.iop_entries[0].variety) ||
            (!id && !postOp.iop_entries[0].time) ||
            !postOp.bcva ||
            !postOp.bcva_method
          }
          primary
          text={"Save"}
          onClick={onSubmit}
        />
      </Form>
    </React.Fragment>
  );
}

PostOpForm.defaultProps = {
  existingVisits: [],
  id: null,
  postOpData: null,
  procedureId: null,
  procedureDate: null,
  onSuccess: () => {},
};
