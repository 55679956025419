export const genderOptions = [
  { text: "Male", value: 1 },
  { text: "Female", value: 2 },
  { text: "Unknown", value: 3 },
  { text: "Non-Binary", value: 4 },
]
  .map((m) => ({ ...m, key: m.value }))
  .sort((a, b) => (a.text > b.text ? 1 : -1));

export const ethnicityOptions = [
  { text: "Caucasian", value: 1 },
  { text: "Hispanic/Latino", value: 2 },
  { text: "Asian", value: 3 },
  { text: "Black/African Descent", value: 4 },
  { text: "Other", value: 5 },
  { text: "Indian", value: 6 },
  { text: "Japanese", value: 7 },
  { text: "Malaysian", value: 8 },
  { text: "Unknown", value: 9 },
]
  .map((m) => ({ ...m, key: m.value }))
  .sort((a, b) => (a.text > b.text ? 1 : -1));
