import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form } from "semantic-ui-react";
import styled from "styled-components";
import toast from "react-hot-toast";

import {
  Button,
  BasicGrid,
  JoinSection,
  Text,
  OptionButton,
} from "@components/shared";
import { getUuid } from "@util/getUuid";

const sections = [
  {
    title: "Systemic Comorbidities",
    entityName: "Comorbidity",
    retrievalData: {
      url: "/retrieve-comorbidities/",
      req: { variety: 1, status: [1, 2] },
    },
    addData: {
      url: `/comorbidities/`,
      req: { variety: 1 },
    },
  },
  {
    title: "Ocular Comorbidities",
    entityName: "Comorbidity",
    retrievalData: {
      url: "/retrieve-comorbidities/",
      req: { variety: 2, status: [1, 2] },
    },
    addData: {
      url: `/comorbidities/`,
      req: { variety: 2 },
    },
  },
  {
    title: "Glaucoma Diagnoses",
    entityName: "GlaucomaDiagnosis",
    retrievalData: {
      url: "/retrieve-diagnosis/",
      req: { status: [1, 2] },
    },
    addData: {
      url: `/diagnosis/`,
      req: {},
    },
  },
  {
    title: "Previous Ocular Surgery",
    entityName: "PreviousOcularSurgery",
    retrievalData: {
      url: "/retrieve-pos/",
      req: { status: [1, 2] },
    },
    addData: {
      url: `/pos/`,
      req: {},
    },
  },
  // {
  //   title: "Systemic Medications",
  //   entityName: "Medication",
  //   retrievalData: {
  //     url: "/retrieve-meds/",
  //     req: { variety: 1, status: [1, 2] },
  //   },
  //   addData: {
  //     url: `/meds/`,
  //     req: { variety: 1 },
  //   },
  // },
  {
    title: "Ocular Medications",
    entityName: "Medication",
    retrievalData: {
      url: "/retrieve-meds/",
      req: { variety: 2, status: [1, 2] },
    },
    addData: {
      url: `/meds/`,
      req: { variety: 2 },
    },
  },
  {
    title: "Gonioscopy Procedures",
    entityName: "Gonioscopy",
    retrievalData: {
      url: "/retrieve-gonioscopies/",
      req: { status: [1, 2] },
    },
    addData: {
      url: `/gonioscopies/`,
      req: {},
    },
  },
  {
    title: "Retinal Findings",
    entityName: "RetinalFinding",
    retrievalData: {
      url: "/retrieve-retinal-findings/",
      req: { status: [1, 2] },
    },
    addData: {
      url: `/retinal-findings/`,
      req: {},
    },
  },
  {
    title: "SLE Findings",
    entityName: "SleFinding",
    retrievalData: {
      url: "/retrieve-sle-findings/",
      req: { status: [1, 2] },
    },
    addData: {
      url: `/sle-findings/`,
      req: {},
    },
  },
  {
    title: "Cataract Statuses",
    entityName: "CataractStatus",
    retrievalData: {
      url: "/retrieve-cataract-statuses/",
      req: { status: [1, 2] },
    },
    addData: {
      url: `/cataract-statuses/`,
      req: {},
    },
  },
  {
    title: "Anesthesia",
    entityName: "Anesthesia",
    retrievalData: {
      url: "/retrieve-anesthesias/",
      req: { status: [1, 2] },
    },
    addData: {
      url: `/anesthesias/`,
      req: {},
    },
  },
  {
    title: "Complications",
    entityName: "Complication",
    retrievalData: {
      url: "/retrieve-complications/",
      req: { status: [1, 2] },
    },
    addData: {
      url: `/complications/`,
      req: {},
    },
  },
].map((m, i) => ({
  ...m,
  id: getUuid(),
  key: i,
}));

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  width: 95%;
  margin: 0 auto;
  max-width: 1250px;

  .nav {
    margin-right: 4em;

    button {
      padding: 0;
      background: none;
      outline: none;
      border: none;
      cursor: pointer;

      &.active {
        color: var(--primaryColorDark);
        font-weight: bold;
      }
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    li {
      margin: 0;
      padding: 8px 0;
    }
  }

  .active {
    flex-grow: 1;
  }
`;

export default function OptionsManagement() {
  const [activeItemId, setActiveItem] = useState(sections[0].id);

  const activeItem = sections.find((f) => f.id === activeItemId);

  return (
    <Wrapper>
      <div className="nav">
        <ul>
          {sections.map((s) => (
            <li key={s.id}>
              <button
                className={s.id === activeItemId ? "active" : ""}
                onClick={(e) => {
                  e.preventDefault();
                  setActiveItem(s.id);
                }}
                type="button"
              >
                {s.title}
              </button>
            </li>
          ))}
        </ul>
      </div>
      <div className="active">{activeItem && <Section {...activeItem} />}</div>
    </Wrapper>
  );
}

const Section = ({ title, entityName, retrievalData, addData }) => {
  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [error, setError] = useState(null);

  if (!retrievalData) return null;

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    axios
      .post(retrievalData.url, retrievalData.req)
      .then(({ data }) => {
        setOptions(data.response);
        setOpen(false);
        setLoading(false);
      })
      .catch((err) => {
        setOptions([]);
        setLoading(false);
      });
  }

  function onOptionClick(id) {
    if (!entityName) return;

    const req = {
      entity: entityName,
      id,
    };

    setLoading(true);
    setError(null);

    axios
      .post(`/option-toggle/`, req)
      .then(() => {
        setLoading(false);
        fetchData();
        toast.success("Saved");
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }

  const activeOptions = options
    .filter((f) => f.status === 1)
    .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
  const inactiveOptions = options
    .filter((f) => f.status !== 1)
    .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));

  const activeSection = (
    <BasicGrid columns={3}>
      {activeOptions.map((opt) => (
        <OptionButton
          key={opt.id}
          active
          onClick={() => onOptionClick(opt.id)}
          text={opt.name}
        />
      ))}
    </BasicGrid>
  );

  return (
    <JoinSection
      title={title}
      error={error}
      loading={loading}
      activeSection={activeSection}
      toggleOpen={isOpen}
      addSection={
        <React.Fragment>
          {addData && (
            <div
              style={{
                marginTop: "1em",
                marginBottom: "1.5em",
                borderBottom: "1px solid #eee",
                paddingBottom: "1em",
              }}
            >
              <AddOptionForm
                addData={addData}
                onSuccess={() => {
                  fetchData();
                  setOpen(!isOpen);
                }}
              />
            </div>
          )}
          {inactiveOptions.length > 0 ? (
            <React.Fragment>
              <h4>Inactive Options</h4>
              <p>Click option to make active</p>
              <BasicGrid>
                {inactiveOptions.map((opt) => (
                  <OptionButton
                    key={opt.id}
                    text={opt.name}
                    onClick={() => onOptionClick(opt.id)}
                  />
                ))}
              </BasicGrid>
            </React.Fragment>
          ) : (
            <p>When there are inactive options, they will appear here.</p>
          )}
        </React.Fragment>
      }
    />
  );
};

Section.defaultProps = {
  entityName: "",
  retrievalData: null,
  title: "Title",
};

const AddOptionForm = ({ addData, onSuccess }) => {
  const [error, setError] = useState(null);
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);

  function onSubmit() {
    setError(null);
    setLoading(true);

    const req = {
      ...addData.req,
      name,
    };

    axios
      .post(addData.url, req)
      .then(() => {
        toast.success("Option Created!");
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }

  return (
    <Form>
      <Form.Input
        label="Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      {error && <Text.Error text={error} />}
      <Button.Basic
        text="Save"
        loading={loading}
        primary
        onClick={onSubmit}
        type="submit"
        disabled={!name || loading}
      />
    </Form>
  );
};
