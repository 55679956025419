import React, { useState, useEffect } from "react";
import axios from "axios";

import { Wrapped } from "@components/Layouts";
import { PageTitle, ViewError } from "@components/shared";
import { AdminForm } from "@components/Admin";
import PhoneManagement from "@components/PhoneManagement";

export default function AdminDetail({ match }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [admin, setAdmin] = useState(populateAdmin());

  const adminId = match.params.id;

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    setLoading(true);

    axios
      .get(`/admins/${adminId}/`)
      .then(({ data }) => {
        setLoading(false);
        setAdmin(populateAdmin(data.response[0]));
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }

  if (error) {
    return <ViewError text={error} />;
  }

  if (!admin) return null;

  return (
    <div>
      <PageTitle
        title={`${admin.first_name} ${admin.last_name}`}
        subtitle="Admin"
      />
      <Wrapped>
        <AdminForm
          email={admin.email}
          firstName={admin.first_name}
          id={adminId}
          lastName={admin.last_name}
          onSuccess={fetchData}
          status={admin.status}
          title={admin.title}
          variety={admin.variety}
        />
      </Wrapped>
      <PhoneManagement adminId={adminId} />
    </div>
  );
}

function populateAdmin(d = {}) {
  return {
    email: d.email || "",
    first_name: d.first_name || "",
    last_name: d.last_name || "",
    status: d.status || null,
    title: d.title || "",
    variety: d.variety || null,
  };
}
