import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

import { Wrapped } from "@components/Layouts";
import PhoneForm from "./PhoneForm";
import { BasicGrid, Button, Card, EmptyList, Icon } from "@components/shared";
import { getRoleInfo } from "@util/roleInfo";

export default function PhoneManagement({
  adminId,
  patientId,
  practiceId,
  userId,
}) {
  const [phones, setPhones] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isOpen, setOpen] = useState(false);

  const roleInfo = getRoleInfo(useSelector((state) => state));

  useEffect(() => {
    fetchPhones();
  }, []);

  function fetchPhones() {
    const req = {};

    setLoading(true);

    if (adminId) {
      req.admin_id = adminId;
    }

    if (userId) {
      req.user_id = userId;
    }

    if (practiceId) {
      req.practice_id = practiceId;
    }

    if (patientId) {
      req.patient_id = patientId;
    }

    axios
      .post(`/retrieve-phones/`, req)
      .then(({ data }) => {
        setPhones(data.response.sort((a, b) => b.primary - a.primary));
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setPhones([]);
      });
  }

  return (
    <Wrapped
      title="Phone Numbers"
      button={
        <Button.Toggle
          closedText="Add"
          onClick={() => setOpen(!isOpen)}
          open={isOpen}
        />
      }
    >
      {!isOpen && (
        <React.Fragment>
          {!loading && phones.length === 0 && (
            <EmptyList text="No phones yet..." />
          )}
          <List numbers={phones} fetchData={fetchPhones} />
        </React.Fragment>
      )}
      {isOpen && (
        <React.Fragment>
          {(!patientId ||
            (patientId && roleInfo.user_doctor) ||
            (patientId && patientId && roleInfo.user_proxy)) && (
            <div style={{ marginTop: "1.5em" }}>
              <PhoneForm
                adminId={adminId}
                patientId={patientId}
                userId={userId}
                practiceId={practiceId}
                onSuccess={() => {
                  fetchPhones();
                  setOpen(false);
                }}
              />
            </div>
          )}
        </React.Fragment>
      )}
    </Wrapped>
  );
}

PhoneManagement.defaultProps = {
  adminId: null,
  practiceId: null,
  userId: null,
};

const List = ({ numbers, fetchData }) => {
  if (!numbers.length) return null;

  return (
    <BasicGrid columns={3}>
      {numbers.map((n) => (
        <Item
          key={n.id}
          number={n.number}
          primary={n.primary}
          id={n.id}
          fetchData={fetchData}
        />
      ))}
    </BasicGrid>
  );
};

List.defaultProps = {
  numbers: [],
};

const Item = ({ id, number, primary, fetchData }) => {
  function onDeleteClick() {
    axios
      .delete(`/phones/${id}/`)
      .then(() => {
        toast.success("Deleted!");
        fetchData();
      })
      .catch((err) => {
        toast.error(err);
        fetchData();
      });
  }

  function onPrimaryClick() {
    axios
      .post(`/phones/${id}/mark-primary/`)
      .then(() => {
        toast.success("New primary phone!");
        fetchData();
      })
      .catch((err) => {
        toast.error(err);
        fetchData();
      });
  }

  return (
    <Card.Wrapper>
      <Card.Meta title={number} hasActions />
      <Card.Actions>
        {!primary && (
          <React.Fragment>
            <Button.Icon
              onClick={(e) => {
                e.preventDefault();
                onPrimaryClick();
              }}
              title="Make Primary"
              icon={<Icon.Star color="gold" />}
              opacity={0.25}
            />
            <Button.Icon
              onClick={(e) => {
                e.preventDefault();
                onDeleteClick();
              }}
              title="Remove"
              icon={<Icon.Remove color="var(--primaryColorDark)" />}
              opacity={0.65}
            />
          </React.Fragment>
        )}
        {primary && <Icon.Star color="gold" title="Primary Number" />}
      </Card.Actions>
    </Card.Wrapper>
  );
};

Item.defaultProps = {
  number: "",
  primary: false,
};
