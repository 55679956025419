import Dashboard from "./Dashboard";
import DoctorsIndex from "./DoctorsIndex";
import PatientsDetail from "./PatientsDetail";
import PatientsIndex from "./PatientsIndex";
import PostOpDetail from "./PostOpDetail";
import PracticeUsersDetail from "./PracticeUsersDetail";
import PracticeUsersIndex from "./PracticeUsersIndex";
import ProcedureDetail from "./ProcedureDetail";
import ProcedureIndex from "./ProcedureIndex";
import Profile from "./Profile";
import QueryContainerDetail from "./QueryContainerDetail";
import RegistryRequestDetail from "./RegistryRequestDetail";
import RegistryRequestIndex from "./RegistryRequestIndex";
import ReportsIndex from "./ReportsIndex";

export {
  Dashboard,
  DoctorsIndex,
  PatientsDetail,
  PatientsIndex,
  PostOpDetail,
  PracticeUsersDetail,
  PracticeUsersIndex,
  ProcedureDetail,
  ProcedureIndex,
  Profile,
  QueryContainerDetail,
  RegistryRequestDetail,
  RegistryRequestIndex,
  ReportsIndex,
};
