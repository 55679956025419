export const visitValues = {
  // baseline: "baseline",
  consistent: "consistent",
  non_consistent: "non-consistent",
};

export const visitOptions = [
  { name: "1 day", key_name: "1d", value: 1 },
  { name: "1 week", key_name: "1w", value: 2 },
  { name: "1 month", key_name: "1m", value: 3 },
  { name: "3 months", key_name: "3m", value: 4 },
  { name: "6 months", key_name: "6m", value: 5 },
  { name: "9 months", key_name: "9m", value: 6 },
  { name: "12 months", key_name: "12m", value: 7 },
  { name: "15 months", key_name: "15m", value: 8 },
  { name: "18 months", key_name: "18m", value: 9 },
  { name: "24 months", key_name: "24m", value: 10 },
  { name: "27 months", key_name: "27m", value: 11 },
  { name: "30 months", key_name: "30m", value: 12 },
  { name: "33 months", key_name: "33m", value: 13 },
  { name: "36 months", key_name: "36m", value: 14 },
];
