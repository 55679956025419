import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form, Modal } from "semantic-ui-react";
import toast from "react-hot-toast";

import { Button, DateField, Icon } from "@components/shared";

export default function ComplicationResolution({
  minDate,
  joinId,
  procedureId,
  postopId,
  fetchData,
}) {
  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    resolution_date: null,
    resolution_note: "",
  });

  function onClose() {
    setOpen(false);
    setLoading(false);
    setFormData({
      resolution_date: null,
      resolution_note: "",
    });
  }

  function onSubmit() {
    setLoading(true);

    const req = {
      ...formData,
      id: joinId,
      resolved: true,
    };

    if (postopId) req.post_op_id = postopId;
    if (procedureId) req.procedure_id = procedureId;

    axios
      .post(
        `/update-${procedureId ? "procedure" : "post-op"}-complication/`,
        req
      )
      .then(() => {
        setLoading(false);
        toast.success("Resolved!");
        onClose();
        fetchData();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  return (
    <div>
      <Button.Basic primary onClick={() => setOpen(true)} text="Resolve" />
      <Modal open={isOpen} onClose={onClose} size="small">
        <Modal.Content>
          <Form>
            <Form.TextArea
              label="Note"
              value={formData.resolution_note}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  resolution_note: e.target.value,
                })
              }
            />
            <Form.Field>
              <label>Resolved Date</label>
              <DateField
                minDate={minDate ? new Date(minDate) : null}
                selected={formData.resolution_date}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    resolution_date: e,
                  })
                }
                maxDate={new Date()}
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button.Basic
              text="Mark Resolved"
              onClick={onSubmit}
              primary
              loading={loading}
              disabled={loading || !formData.resolution_date}
            />
          </div>
        </Modal.Actions>
      </Modal>
    </div>
  );
}

ComplicationResolution.defaultProps = {
  minDate: null,
};
