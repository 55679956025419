import React, { useState, useEffect } from "react";
import { FaArrowCircleRight, FaArrowCircleDown } from "react-icons/fa";
import axios from "axios";
import Loader from "react-loader-spinner";
import styled from "styled-components";

import { AuditList } from "./";
import { EmptyList } from "@components/shared";
import { getUuid } from "@util/getUuid";

const loaderConfig = {
  type: "ThreeDots",
  height: 15,
  width: 15,
  color: "#000",
  style: {
    marginLeft: "5px",
  },
};

export default function AuditManagement({ reqInfo, updateInfo, onUpdate }) {
  const [items, setItems] = useState([]);
  const [joins, setJoins] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchJoins();
  }, []);

  function fetchJoins() {
    axios
      .post(`/retrieve-audit-joins/`, {
        ...reqInfo,
      })
      .then(({ data }) => {
        setJoins(
          data.response
            .filter((f) => f.items.length)
            .map((m) => ({
              ...m,
              uuid: getUuid(),
            }))
        );
        setLoading(false);
      })
      .catch((err) => {
        setJoins([]);
        setLoading(false);
      });
  }

  function fetchData() {
    setLoading(true);

    axios
      .post(`/retrieve-audit-items/`, {
        ...reqInfo,
      })
      .then(({ data }) => {
        setItems(
          data.response
            .sort((a, b) =>
              new Date(a.created_at) < new Date(b.created_at) ? 1 : -1
            )
            .map((r) => ({
              ...r,
              uuid: getUuid(),
              parse_info: r.parse_info
                ? r.parse_info.map((p) => ({ ...p, uuid: getUuid() }))
                : [],
            }))
        );
      })
      .catch((err) => {
        setItems([]);
        setLoading(false);
      });
  }

  if (!loading && items.length === 0 && joins.length === 0) {
    return <EmptyList text="No audit items yet..." />;
  }

  return (
    <React.Fragment>
      <AuditList items={items} updateInfo={updateInfo} />
      {joins.map((j) => (
        <JoinSection
          key={j.uuid}
          name={j.parent_name}
          joinKey={j.join_key}
          items={j.items}
        />
      ))}
    </React.Fragment>
  );
}

AuditManagement.defaultProps = {
  updateInfo: null,
};

const JoinSection = ({ name, joinKey, items }) => {
  return (
    <React.Fragment>
      <h2 style={{ marginBottom: "0" }}>{name}</h2>
      {items.map((item) => (
        <JoinItem
          key={item.id}
          name={item.name}
          parent={item.parent_name}
          joinKey={joinKey}
          id={item.id}
        />
      ))}
    </React.Fragment>
  );
};

JoinSection.defaultProps = {
  items: [],
};

const JoinStyle = styled.div`
  padding: 1em 0;

  & + & {
    border-top: 1px solid #eee;
  }

  div.title {
    display: flex;
    align-items: center;
    font-size: 1.5em;
    font-weight: bold;

    // this is the grid
    & + div {
      margin-top: 1em;
    }

    h3 {
      margin: 0;
    }

    button {
      padding: 0;
      margin-right: 5px;
      cursor: pointer;
      background: none;
      outline: none;
      border: none;
      display: flex;
      align-items: center;
      font-weight: bold;

      svg {
        margin-right: 5px;
        color: var(--primaryColorLight);
      }
    }

    p {
      flex-grow: 1;
      justify-content: flex-end;
      font-size: 16px;
      font-weight: 400;
    }
  }
`;

const JoinItem = ({ name, parent, id, joinKey }) => {
  const [isOpen, setOpen] = useState(false);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, [isOpen]);

  function fetchData() {
    if (!isOpen) return setLoading(false);

    setLoading(true);

    const req = {
      [joinKey]: id,
    };

    axios
      .post(`/retrieve-audit-items/`, req)
      .then(({ data }) => {
        setItems(
          data.response
            .sort((a, b) =>
              new Date(a.created_at) < new Date(b.created_at) ? 1 : -1
            )
            .map((r) => ({
              ...r,
              uuid: getUuid(),
              parse_info: r.parse_info
                ? r.parse_info.map((p) => ({ ...p, uuid: getUuid() }))
                : [],
            }))
        );
        setLoading(false);
      })
      .catch((err) => {
        setItems([]);
        setLoading(false);
      });
  }

  return (
    <JoinStyle>
      <div className="title">
        <button onClick={() => setOpen(!isOpen)}>
          {isOpen ? <FaArrowCircleDown /> : <FaArrowCircleRight />}
        </button>
        <h3>{name}</h3>
        {loading && <Loader {...loaderConfig} />}
      </div>
      {isOpen && (
        <div>
          {items.length === 0 && <EmptyList text="No audit items yet" />}
          <AuditList items={items} />
        </div>
      )}
    </JoinStyle>
  );
};
