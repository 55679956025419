import React, { useState, useEffect } from "react";
import axios from "axios";

import { BasicGrid, Icon, Card, Button } from "@components/shared";

export default function PatientPractices({ patientId }) {
  const [practices, setPractices] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    axios
      .get(`/patients/${patientId}/practices/`)
      .then(({ data }) => {
        setPractices(data.response);
      })
      .catch((err) => {});
  }

  return (
    <BasicGrid>
      {practices.map((p) => (
        <Card.Wrapper key={p.id}>
          <Card.Meta title={p.name} />
          <Card.Actions>
            <Button.Link
              text={<Icon.Eye size={26} />}
              to={`/practices/${p.id}`}
            />
          </Card.Actions>
        </Card.Wrapper>
      ))}
    </BasicGrid>
  );
}
