export default function formatEye(eyeInt) {
  switch (eyeInt) {
    case 1:
      return "OS";
    case 2:
      return "OD";
    case 3:
      return "OU";
    default:
      return "";
  }
}
