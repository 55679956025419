import React, { useState, useEffect } from "react";
import axios from "axios";
import toast from "react-hot-toast";

import {
  BasicGrid,
  JoinNote,
  JoinSection,
  OptionButton,
  OptionItem,
} from "@components/shared";
import formatDate from "@util/formatDate";
import { ProcedureJoinForm, ProcedureJoinEdit } from "./";

import {
  formatRetinalOption,
  formatCataractOption,
  formatGonioscopyOption,
} from "@util/optionJoinFormatters";

export default function ProcedureJoins({
  addData,
  detailMode,
  entity,
  isCataractStatus,
  isDiagnosis,
  isGonioscopy,
  isMeds,
  isPos,
  isRetinalFinding,
  isAdmin,
  nameFormatter,
  onUpdate,
  optionsRetrievalData,
  postOpId,
  rrId,
  removeData,
  retrievalData,
  selectedEye,
  title,
  viewOnly,
}) {
  const [pristineOptions, setPristineOptions] = useState([]);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [editData, setEditData] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    if (!optionsRetrievalData) return;

    setLoading(true);

    axios
      .post(optionsRetrievalData.url, optionsRetrievalData.req)
      .then(({ data }) => {
        fetchJoins(data.response);
        setPristineOptions(data.response);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setOptions([]);
      });
  }

  function fetchJoins(newOptions = [...options]) {
    if (!retrievalData) return;

    axios
      .post(retrievalData.url, { ...retrievalData.req })
      .then(({ data }) => {
        setOptions(
          [...newOptions].map((m) => {
            const joins = data.response.filter(
              (f) => f[`${retrievalData.option_key}`] === m.id
            );
            const joinInfo = joins.length ? joins[joins.length - 1] : null;

            return {
              active: joinInfo ? true : false,
              ...joinInfo,
              ...m,
            };
          })
        );
      })
      .then(() => setLoading(false))
      .then(() => {
        if (onUpdate) {
          onUpdate();
        }
      })
      .catch((err) => {});
  }

  function onAddClick(options) {
    if (!addData) return;

    setLoading(true);
    setError(null);

    const req = {
      ...addData.req,
      ...options,
      date_added: new Date(),
    };

    axios
      .post(addData.url, req)
      .then(() => {
        fetchJoins(pristineOptions);
        toast.success("Added!");
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  function onRemoveClick(joinId) {
    if (!removeData || !joinId) return;

    setLoading(true);
    setError(null);

    const req = {
      entity,
      // ...removeData.req,
      id: joinId,
    };

    axios
      .post(removeData.url, req)
      .then(() => {
        fetchJoins(pristineOptions);
        toast.success("Removed!");
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  }

  const activeItems = options.filter((f) => f.active);
  const activeCount = activeItems.length;

  const activeSection = (
    <React.Fragment>
      {editData && (
        <ProcedureJoinEdit
          editData={editData}
          postOpId={postOpId}
          onUpdate={() => {
            setEditData(null);
            fetchData();
          }}
        />
      )}
      {activeItems.length > 0 && (
        <BasicGrid>
          {options
            .filter((f) => f.active)
            .map((opt) => (
              <OptionItem
                viewOnly={viewOnly}
                hasNote={viewOnly ? false : opt.description ? true : false}
                key={opt.id}
              >
                <OptionButton
                  active
                  description={
                    isMeds
                      ? opt.frequency_formatted
                        ? `${opt.frequency_formatted}`
                        : ""
                      : isRetinalFinding
                      ? formatRetinalOption(opt)
                      : isCataractStatus
                      ? formatCataractOption(opt)
                      : isGonioscopy
                      ? formatGonioscopyOption(opt)
                      : opt.date_added || opt.first_diagnosed_at || opt.date
                      ? `added ${formatDate(
                          opt.date
                            ? opt.date
                            : opt.date_added
                            ? opt.date_added
                            : opt.first_diagnosed_at
                        )}`
                      : ""
                  }
                  note={opt.description}
                  key={opt.id}
                  text={nameFormatter ? nameFormatter(opt) : opt.name}
                  onClick={() =>
                    viewOnly
                      ? false
                      : !detailMode
                      ? !loading
                        ? onRemoveClick(opt.join_id)
                        : null
                      : setEditData(opt)
                  }
                />
                {removeData && !viewOnly && (
                  <JoinNote
                    entityName={entity}
                    joinId={opt.join_id}
                    onSuccess={fetchData}
                    optionName={opt.name}
                    noteValue={opt.description || ""}
                  />
                )}
              </OptionItem>
            ))}
        </BasicGrid>
      )}
    </React.Fragment>
  );

  return (
    <JoinSection
      title={title}
      error={error}
      activeCount={activeCount}
      loading={loading}
      viewOnly={viewOnly}
      activeSection={activeSection}
      addSection={
        <React.Fragment>
          <ProcedureJoinForm
            isCataractStatus={isCataractStatus}
            isDiagnosis={isDiagnosis}
            isPos={isPos}
            isMeds={isMeds}
            isGonioscopy={isGonioscopy}
            isRetinalFinding={isRetinalFinding}
            options={options
              .filter((f) => !f.active)
              .sort((a, b) => (a.name > b.name ? 1 : -1))}
            onAddClick={onAddClick}
            selectedEye={selectedEye}
          />
        </React.Fragment>
      }
    />
  );
}

ProcedureJoins.defaultProps = {
  addData: null,
  detailMode: false,
  isCataractStatus: false,
  isDiagnosis: false,
  isMeds: false,
  isPos: false,
  isGonioscopy: false,
  isRetinalFinding: false,
  joins: [],
  nameFormatter: null,
  removeData: null,
  optionsRetrievalData: null,
  retrievalData: null,
  selectedEye: null,
  title: "Join Management",
  viewOnly: false,
};
