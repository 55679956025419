import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Form, Modal } from "semantic-ui-react";
import styled from "styled-components";
import { getUuid } from "@util/getUuid";
import { FaCheckCircle, FaMinusCircle, FaTimesCircle } from "react-icons/fa";

import { Context } from "./";

import ValueBlock from "./ValueBlock";
import { procedureJoinOptions, procedureJoinState } from "./helpers";
import { BasicGrid, Button, Icon, InlineLoader } from "@components/shared";

import { MODE_INCLUSIVE, MODE_EXCLUSIVE, MODE_NEVER } from "./helpers";

const ModeIcon = ({ mode }) => {
  if (mode === MODE_EXCLUSIVE) {
    return <FaMinusCircle color="#e3dc24" />;
  }

  if (mode === MODE_NEVER) {
    return <FaTimesCircle color="var(--negativeColor)" />;
  }

  return <FaCheckCircle color="var(--positiveColor)" />;
};

export default function JoinPickers({ onSetupChange }) {
  const { loading, procedureJoinInfo } = useContext(Context);
  const [items, setItems] = useState(procedureJoinState);
  const [fetching, setFetching] = useState(true);
  const [adding, setAdding] = useState(false);

  useEffect(() => {
    if (procedureJoinInfo.length === 0) {
      setAdding(true);
    }
  }, [JSON.stringify(procedureJoinInfo)]);

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    setFetching(true);

    axios
      .get(`/join-parents/`)
      .then(({ data }) => {
        setItems(data.response[0]);
        setFetching(false);
      })
      .catch((err) => {
        setFetching(false);
      });
  }

  function onValueAdd(keyName, value) {
    const newSetup = [...procedureJoinInfo];
    const obj = newSetup.find((f) => f.key_name === keyName);
    if (!obj) return;
    obj.data = [...obj.data, value];
    onSetupChange(newSetup);
  }

  function onValueRemove(keyName, value) {
    const newSetup = [...procedureJoinInfo];
    const obj = newSetup.find((f) => f.key_name === keyName);
    if (!obj) return;
    obj.data = [...obj.data].filter((f) => f.id !== value);
    onSetupChange(newSetup);
  }

  function onModeSwitch(keyName, newMode) {
    const newSetup = [...procedureJoinInfo];
    const obj = newSetup.find((f) => f.key_name === keyName);
    if (!obj) return;
    obj.mode = newMode;
    if (newMode === MODE_NEVER) {
      obj.data = [];
    }
    onSetupChange(newSetup);
  }

  if (fetching)
    return (
      <div
        style={{
          minHeight: "500px",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          textAlign: "center",
          justifyContent: "center",
          fontSize: "1.35em",
          fontWeight: "bold",
        }}
      >
        <InlineLoader />
        <p>Loading</p>
      </div>
    );

  return (
    <React.Fragment>
      {procedureJoinInfo.length > 0 &&
        procedureJoinInfo.length !== procedureJoinOptions.length && (
          <Button.Text
            onClick={() => setAdding(!adding)}
            text={adding ? "Close" : "Add more criteria"}
            style={{
              marginBottom: adding ? "1em" : 0,
              marginTop: "0",
              color: !adding ? "var(--secondaryColor)" : "var(--negativeColor)",
            }}
          />
        )}
      {adding && (
        <JoinSetup
          onSubmit={(e) => {
            onSetupChange([...procedureJoinInfo, e]);
            setAdding(false);
          }}
        />
      )}
      {!adding && (
        <React.Fragment>
          {procedureJoinInfo.map((m) => (
            <JoinSection
              key={m.key_name}
              name={m.text}
              mode={m.mode}
              values={m.data}
              onModeSwitch={(e) => onModeSwitch(m.key_name, e)}
              onValueAdd={(e) => onValueAdd(m.key_name, e)}
              onValueRemove={(e) => onValueRemove(m.key_name, e)}
              options={items[m.key_name].sort((a, b) =>
                a.name > b.name ? 1 : -1
              )}
              loading={loading}
              onRemoveClick={() =>
                onSetupChange(
                  [...procedureJoinInfo].filter(
                    (f) => f.key_name !== m.key_name
                  )
                )
              }
            />
          ))}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

const switchButtons = [
  { text: "Switch to inclusive", value: MODE_INCLUSIVE },
  { text: "Switch to exclusive", value: MODE_EXCLUSIVE },
  { text: "Switch to never", value: MODE_NEVER },
].map((m, i) => ({ ...m, key: i + 1, icon: <ModeIcon mode={m.value} /> }));

const JoinSection = ({
  loading,
  mode,
  name,
  onModeSwitch,
  onRemoveClick,
  onValueAdd,
  onValueRemove,
  options,
  values,
}) => {
  return (
    <StyledJoinSection>
      <div className="meta">
        <h3>
          {name} ({mode})
        </h3>
        {[MODE_INCLUSIVE, MODE_EXCLUSIVE].includes(mode) && (
          <JoinPicker
            onChange={onValueAdd}
            onRemove={onValueRemove}
            loading={loading}
            options={options}
            values={values}
          />
        )}
      </div>
      <p>
        {mode === MODE_INCLUSIVE &&
          (values.length === 0
            ? "Pick some options you want procedures in your query to have"
            : "Procedures in your query will have...")}
        {mode === MODE_EXCLUSIVE &&
          (values.length === 0
            ? "Pick some options you don't want procedures in your query to have"
            : "Procedures in your query will not have...")}
        {mode === MODE_NEVER &&
          `Procedures in your query will have never had ${name}`}
      </p>
      {values.length > 0 && (
        <JoinValues values={values} onRemoveClick={onValueRemove} />
      )}
      <div
        className="actions"
        style={{ marginTop: values.length > 0 ? "1em" : 0 }}
      >
        <button className="remove" onClick={onRemoveClick}>
          <Icon.Remove /> Remove from query
        </button>
        {switchButtons
          .filter((f) => f.value !== mode)
          .map((m) => (
            <button onClick={() => onModeSwitch(m.value)} key={m.key}>
              {m.icon} {m.text}
            </button>
          ))}
      </div>
    </StyledJoinSection>
  );
};

JoinSection.defaultProps = {
  loading: false,
  options: [],
  values: [],
};

const StyledJoinSection = styled.div`
  padding: 1em 0;

  &:first-of-type {
    padding-top: 0;
  }

  &:last-of-type {
    padding-bottom: 0;
  }

  .meta {
    display: flex;
    align-items: center;
    margin-bottom: 0.5em;

    h3 {
      margin-bottom: 0;
      margin-right: 8px;
    }
  }

  & + & {
    border-top: 2px solid rgba(0, 0, 0, 0.05);
  }

  .actions {
    display: flex;
    align-items: center;

    button.remove {
      svg {
        fill: var(--negativeColor);
        opacity: 1;
      }
    }

    button {
      display: flex;
      align-items: center;
      cursor: pointer;
      font-family: inherit;
      border: none;
      outline: none;
      background: none;
      padding: 0;
      margin: 0;
      color: var(--greyText);
      font-weight: bold;
      font-size: 12px;

      svg {
        margin-right: 5px;
        font-size: 18px;
        fill: var(--greyText);
        opacity: 0.35;
      }
    }

    button + button {
      margin-left: 14px;
    }
  }
`;

const StyledJoinPicker = styled.div`
  & + & {
    margin-top: 1.5em;
  }

  .meta {
    display: flex;
    align-items: center;
    margin-bottom: 0.5em;

    h3 {
      margin-bottom: 0;
      margin-right: 8px;
    }
  }
`;

const JoinPicker = ({ onChange, loading, labelText, options }) => {
  const [selected, setSelected] = useState(null);
  const [isOpen, setOpen] = useState(false);

  function onClose() {
    setSelected(null);
    setOpen(false);
  }

  function onInputChange(id) {
    const obj = options.find((f) => f.id === id);
    if (!obj) return;

    setSelected(obj);
  }

  function onSubmit() {
    onChange({
      ...selected,
    });
    onClose();
  }

  return (
    <StyledJoinPicker>
      <Button.Toggle
        disabled={loading}
        opacity={loading ? "0.5" : "1"}
        open={isOpen}
        onClick={() => setOpen(!isOpen)}
      />
      <Modal open={isOpen} onClose={onClose} size="small">
        <Modal.Content>
          <Form>
            <Form.Select
              label={labelText}
              search
              placeholder="Select one..."
              options={options.map((m) => ({
                key: m.id,
                value: m.id,
                text: m.name,
              }))}
              onChange={(e, d) => onInputChange(d.value)}
            />
            {selected &&
              selected.value_array &&
              selected.value_array.length > 0 && (
                <Form.Select
                  label={"Value"}
                  multiple
                  placeholder="Select one..."
                  options={selected.value_array.map((mm) => ({
                    key: mm.value,
                    value: mm.value,
                    text: mm.text,
                  }))}
                  onChange={(e, d) => {
                    const opts = selected.value_array;
                    const selectedOpts = d.value
                      .map((val) => ({
                        value: val,
                        key_name_formatted: "Value",
                        text: opts.find((f) => f.value === val)
                          ? opts.find((f) => f.value === val).text
                          : null,
                      }))
                      .filter((f) => f.text);
                    setSelected({
                      ...selected,
                      value: selectedOpts,
                    });
                  }}
                />
              )}
            {selected &&
              selected.dropdown_data &&
              selected.dropdown_data.data.length > 0 && (
                <React.Fragment>
                  {selected.dropdown_data.data.map((m, i) => (
                    <Form.Select
                      key={i}
                      label={m.label}
                      multiple
                      placeholder="Select one..."
                      options={m.options.map((mm) => ({
                        key: mm.value,
                        value: mm.value,
                        text: mm.text,
                      }))}
                      onChange={(e, d) => {
                        const opts = m.options;
                        const selectedOpts = d.value
                          .map((val) => ({
                            value: val,
                            key_name_formatted: m.label,
                            text: opts.find((f) => f.value === val)
                              ? opts.find((f) => f.value === val).text
                              : null,
                          }))
                          .filter((f) => f.text);
                        setSelected({
                          ...selected,
                          [m.name]: selectedOpts,
                        });
                      }}
                    />
                  ))}
                </React.Fragment>
              )}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button.Basic
            onClick={onSubmit}
            text="Add"
            primary
            disabled={!selected}
          />
        </Modal.Actions>
      </Modal>
    </StyledJoinPicker>
  );
};

JoinPicker.defaultProps = {
  options: [],
  values: [],
};

const JoinSetup = ({ onSubmit }) => {
  const [selected, setSelected] = useState(null);

  const { procedureJoinInfo } = useContext(Context);
  const procedureJoinInfoKeyNames = procedureJoinInfo.map((m) => m.key_name);

  function onModeSelect(mode) {
    onSubmit({ ...selected, mode, data: [] });
    setSelected(null);
  }

  const modeBtns = [
    { text_key: `inclusive_text`, value: MODE_INCLUSIVE },
    { text_key: `exclusive_text`, value: MODE_EXCLUSIVE },
    { text_key: `never_text`, value: MODE_NEVER },
  ].map((m, i) => ({ ...m, key: i + 1, icon: <ModeIcon mode={m.value} /> }));

  return (
    <StyledJoinSetup>
      {!selected && (
        <React.Fragment>
          <p>
            <b>I'd like to know something about...</b>
          </p>
          <div className="selections">
            <BasicGrid columns={3}>
              {procedureJoinOptions
                .filter((f) => !procedureJoinInfoKeyNames.includes(f.key_name))
                .map((m) => (
                  <Button.Basic
                    primary
                    key={m.key}
                    text={m.text}
                    onClick={() => setSelected(m)}
                    disabled={procedureJoinInfoKeyNames.includes(m.key_name)}
                  />
                ))}
            </BasicGrid>
          </div>
        </React.Fragment>
      )}
      {selected && (
        <div>
          <p>
            <b>I would like to find procedures that have...</b>
          </p>
          <BasicGrid columns={3} style={{ alignItems: "initial" }}>
            {modeBtns.map((m) => (
              <button
                className="big-selection"
                key={m.key}
                onClick={() => onModeSelect(m.value)}
              >
                {m.icon}
                <span>{selected[m.text_key]}</span>
              </button>
              // <Button.Basic
              //   text={selected[m.text_key]}
              //   key={m.key}
              //   onClick={() => onModeSelect(m.value)}
              //   primary
              // />
            ))}
          </BasicGrid>
          <div className="escape">
            <Button.Text
              text="Nevermind. Get me out of here."
              onClick={() => setSelected(null)}
            />
          </div>
        </div>
      )}
    </StyledJoinSetup>
  );
};

const StyledJoinSetup = styled.div`
  .selections {
    button {
      width: 100%;
      background: rgba(0, 0, 0, 0.05);
      border-color: rgba(0, 0, 0, 0.1);
      color: inherit;
      transition: all 0.2s ease-in-out;
      font-size: 0.95em;

      &:hover {
        background: rgba(0, 0, 0, 0.1);
      }
    }
  }

  .escape {
    margin: 2em 0 4em 0;
    display: flex;
    justify-content: center;

    button {
      padding: 0;
      margin: 0;
    }
  }

  button.big-selection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 2em;
    border: 1px solid #dfdfdf;
    outline: none;
    font-weight: bold;
    border-radius: 5px;
    transition: all 0.2s ease-in-out;
    min-height: 175px;
    color: inherit;

    &:hover {
      opacity: 0.65;
    }

    svg {
      font-size: 64px;
    }

    span {
      margin-top: 0.85em;
      font-size: 1.05em;
      width: 70%;
      line-height: 1.35;
    }
  }
`;

const JoinValues = ({ values, onRemoveClick, loading }) => {
  return (
    <BasicGrid>
      {values.map((val) => (
        <ValueBlock
          active
          key={getUuid()}
          name={val.name}
          trace={val.trace}
          loading={loading}
          onRemoveClick={() => onRemoveClick(val.id)}
          dropdownInfo={[
            { name: "Trace", value: val.trace || [] },
            { name: "Angular Approach", value: val.angular_approach || [] },
            { name: "Meshwork", value: val.meshwork || [] },
            { name: "Iris Insertion", value: val.iris_insertion || [] },
            { name: "Peripheral Iris", value: val.peripheral_iris || [] },
            { name: "Nerve Variety", value: val.nerve_variety || [] },
            { name: "Value", value: val.value || [] },
          ]
            .map((m, i) => ({
              ...m,
              key: i,
            }))
            .filter((f) => f.value.length > 0)}
        />
      ))}
    </BasicGrid>
  );
};
